<template>
  <div>
    <snackbar :attr="snackbar" />
    <v-dialog v-model="participationDialog" max-width="800px">
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeParticipationDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-expansion-panels accordion multiple v-model="panel">
            <v-expansion-panel>
              <v-expansion-panel-header>General</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="docEdit.urbanLeague.firstEngagement"
                      label="First time at Urban League of Greater Atlanta"
                      id="firstEngagement"
                      name="firstEngagement"
                      hide-details="auto"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6">
                    <v-select
                      v-model="docEdit.urbanLeague.referral"
                      @change="clearReferral"
                      clearable
                      :items="uglaReferrals"
                      label="How did you hear about us?"
                      hide-details="auto"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6">
                    <v-text-field
                      v-model="docEdit.urbanLeague.referralOther"
                      :disabled="docEdit.urbanLeague.referral != 'Other'"
                      id="referralOther"
                      name="referralOther"
                      label="Other"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="docEdit.urbanLeague.enrollmentTrack"
                      clearable
                      :items="enrollmentTracks"
                      label="Enrollment Track"
                      hide-details="auto"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6">
                    <v-checkbox
                      v-model="
                        docEdit.urbanLeague.eligibilityRequirements
                          .eighteenYears
                      "
                      label="At least 18 years old"
                      id="eighteenYears"
                      name="eighteenYears"
                      hide-details="auto"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6">
                    <v-checkbox
                      v-model="
                        docEdit.urbanLeague.eligibilityRequirements
                          .convictedAsAdult
                      "
                      label="Convicted as an adult"
                      id="convictedAsAdult"
                      name="convictedAsAdult"
                      hide-details="auto"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6">
                    <v-checkbox
                      v-model="
                        docEdit.urbanLeague.eligibilityRequirements
                          .startWithin9months
                      "
                      label="Start within 9 months of release"
                      id="startWithin9months"
                      name="startWithin9months"
                      hide-details="auto"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6">
                    <v-checkbox
                      v-model="
                        docEdit.urbanLeague.eligibilityRequirements.noSexCrimes
                      "
                      label="No sex crimes (unless prostitution)"
                      id="noSexCrimes"
                      name="noSexCrimes"
                      hide-details="auto"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6">
                    <v-checkbox
                      v-model="
                        docEdit.urbanLeague.eligibilityRequirements.veteran
                      "
                      label="Veteran"
                      id="veteran"
                      name="veteran"
                      hide-details="auto"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6">
                    <v-checkbox
                      v-model="
                        docEdit.urbanLeague.eligibilityRequirements.workPermit
                      "
                      label="Non-Citizen with a work permit"
                      id="workPermit"
                      name="workPermit"
                      hide-details="auto"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="
                        docEdit.urbanLeague.eligibilityRequirements
                          .returnToMetro
                      "
                      id="returnToMetro"
                      name="returnToMetro"
                      hide-details="auto"
                    >
                      <template v-slot:label>
                        <div>
                          Return to <b>Fulton</b>, <b>DeKalb</b>, <b>Cobb</b>,
                          or <b>Clayton</b> counties upson release
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                >TABE/GAIN/GRADE</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="
                        docEdit.urbanLeague.gradeData.readingSkills9Above
                      "
                      label="Reading skills 9.0 or above"
                      id="readingSkills9Above"
                      name="readingSkills9Above"
                      hide-details="auto"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6">
                    <v-text-field
                      v-model="
                        docEdit.urbanLeague.gradeData.readingSkillsTestScore
                      "
                      id="readingSkillsTestScore"
                      name="readingSkillsTestScore"
                      label="Reading Skills Test Score"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6">
                    <v-menu
                      ref="readingSkillsTestDate"
                      v-model="readingSkillsTestDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="readingSkillsTestDateMDY"
                          :rules="rules"
                          required
                          label="Reading Test Date"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          ><vue-fontawesome
                            :icon="['fas', 'calendar-alt']"
                            slot="prepend-inner"
                            class="mt-1 mr-1"
                          />{{ readingSkillsTestDateYMD }}</v-text-field
                        >
                      </template>
                      <v-date-picker
                        v-model="readingSkillsTestDateYMD"
                        @change="saveDate(readingSkillsTestDateYMD, 'reading')"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text @click="readingSkillsTestDate = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="
                            $refs.readingSkillsTestDate.save(
                              readingSkillsTestDate
                            )
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="docEdit.urbanLeague.gradeData.mathSkills9Above"
                      label="Reading skills 9.0 or above"
                      id="mathSkills9Above"
                      name="mathSkills9Above"
                      hide-details="auto"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6">
                    <v-text-field
                      v-model="
                        docEdit.urbanLeague.gradeData.mathSkillsTestScore
                      "
                      id="mathSkillsTestScore"
                      name="mathSkillsTestScore"
                      label="Math Skills Test Score"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6">
                    <v-menu
                      ref="mathSkillsTestDate"
                      v-model="mathSkillsTestDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="mathSkillsTestDateMDY"
                          :rules="rules"
                          required
                          label="Math Test Date"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          ><vue-fontawesome
                            :icon="['fas', 'calendar-alt']"
                            slot="prepend-inner"
                            class="mt-1 mr-1"
                          />{{ mathSkillsTestDateYMD }}</v-text-field
                        >
                      </template>
                      <v-date-picker
                        v-model="mathSkillsTestDateYMD"
                        @change="saveDate(mathSkillsTestDateYMD, 'math')"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text @click="mathSkillsTestDate = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="
                            $refs.mathSkillsTestDate.save(mathSkillsTestDate)
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Wage/Income</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" sm="6" md="6" lg="6">
                    <v-checkbox
                      v-model="docEdit.urbanLeague.wageIncomeData.wageIncrease"
                      label="Wage increase during participation"
                      id="wageIncrease"
                      name="wageIncrease"
                      hide-details="auto"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6">
                    <v-checkbox
                      v-model="docEdit.urbanLeague.wageIncomeData.promotion"
                      label="Received promotion during participation"
                      id="promotion"
                      name="promotion"
                      hide-details="auto"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6">
                    <v-checkbox
                      v-model="
                        docEdit.urbanLeague.wageIncomeData.disciplinaryAction
                      "
                      label="Disciplinary action during participation"
                      id="disciplinaryAction"
                      name="disciplinaryAction"
                      hide-details="auto"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6">
                    <v-checkbox
                      v-model="docEdit.urbanLeague.wageIncomeData.terminated"
                      label="Terminated during participation"
                      id="terminated"
                      name="terminated"
                      hide-details="auto"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6">
                    <v-checkbox
                      v-model="docEdit.urbanLeague.wageIncomeData.laidOff"
                      label="Laid off during participation"
                      id="laidOff"
                      name="laidOff"
                      hide-details="auto"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                >Emergency Assistance</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                  <v-col
                    cols="12"
                    sm="2"
                    md="3"
                    lg="3"
                    v-for="(assistance, index) in assistances"
                    :key="index"
                  >
                    <v-checkbox
                      v-model="
                        docEdit.urbanLeague.emergencyAssistance[assistance.id]
                      "
                      :value="assistance.id ? true : false"
                      :label="assistance.label"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="docEdit.urbanLeague.emergencyAssistanceOther"
                      id="emergencyAssistanceOther"
                      name="emergencyAssistanceOther"
                      label="Other"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeParticipationDialog"> Close </v-btn>
          <v-btn elevation="0" color="primary" class="mr-4" @click="saveDoc">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row no-gutters>
      <v-col cols="12" class="d-flex flex-row">
        <v-btn
          v-if="canEdit"
          small
          dark
          class="primary-btn"
          elevation="0"
          @click="editParticipation()"
        >
          <vue-fontawesome :icon="['fas', 'pencil-alt']" class="mr-1" />
          Edit
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="12" md="6" lg="6">
        <v-simple-table>
          <tbody>
            <tr>
              <td colspan="2" :width="leftColWidth">
                <span
                  :class="
                    doc.urbanLeague.firstEngagement ? 'font-weight-bold' : ''
                  "
                  >First time at Urban League of Greater Atlanta</span
                >
                <vue-fontawesome
                  v-if="doc.urbanLeague.firstEngagement"
                  :icon="['far', 'check']"
                  class="ml-2"
                />
                <vue-fontawesome v-else :icon="['far', 'ban']" class="ml-2" />
              </td>
            </tr>
            <tr>
              <td colspan="2" :width="leftColWidth">
                <v-row no-gutters>
                  <v-col cols="6" class="text-left">
                    <span v-if="doc.urbanLeague.referral">
                      Referral
                      <b
                        v-if="doc.urbanLeague.referral != 'Other'"
                        class="ml-2"
                        >{{ doc.urbanLeague.referral }}</b
                      >
                    </span>
                    <span v-if="doc.urbanLeague.referralOther" class="mx-2">
                      <b>{{ doc.urbanLeague.referralOther }}</b> (Other)
                    </span>
                  </v-col>
                  <v-col cols="6" class="text-left">
                    <span v-if="doc.urbanLeague.enrollmentTrack">
                      Enrollment Track
                      <b>{{ doc.urbanLeague.enrollmentTrack }}</b>
                    </span>
                  </v-col>
                </v-row>
              </td>
            </tr>
            <tr>
              <td colspan="2" :width="leftColWidth">
                <h3 class="font-weight-regular">Eligibility Requirements</h3>
              </td>
            </tr>
            <tr>
              <td colspan="2" :width="leftColWidth">
                <v-row no-gutters>
                  <v-col cols="6" class="text-left">
                    <span
                      :class="
                        doc.urbanLeague.eligibilityRequirements.eighteenYears
                          ? 'font-weight-bold'
                          : ''
                      "
                      >At least 18 years old</span
                    >
                    <vue-fontawesome
                      v-if="
                        doc.urbanLeague.eligibilityRequirements.eighteenYears
                      "
                      :icon="['far', 'check']"
                      class="ml-2"
                    />
                    <vue-fontawesome
                      v-else
                      :icon="['far', 'ban']"
                      class="ml-2"
                    />
                  </v-col>
                  <v-col cols="6" class="text-left">
                    <span
                      :class="
                        doc.urbanLeague.eligibilityRequirements.convictedAsAdult
                          ? ' font-weight-bold'
                          : ''
                      "
                      >Convicted as an adult</span
                    >
                    <vue-fontawesome
                      v-if="
                        doc.urbanLeague.eligibilityRequirements.convictedAsAdult
                      "
                      :icon="['far', 'check']"
                      class="ml-2"
                    />
                    <vue-fontawesome
                      v-else
                      :icon="['far', 'ban']"
                      class="ml-2"
                    />
                  </v-col>
                </v-row>
              </td>
            </tr>
            <tr>
              <td colspan="2" :width="leftColWidth">
                <v-row no-gutters>
                  <v-col cols="6" class="text-left">
                    <span
                      :class="
                        doc.urbanLeague.eligibilityRequirements
                          .startWithin9months
                          ? ' font-weight-bold'
                          : ''
                      "
                      >Start within 9 months of release</span
                    >
                    <vue-fontawesome
                      v-if="
                        doc.urbanLeague.eligibilityRequirements
                          .startWithin9months
                      "
                      :icon="['far', 'check']"
                      class="ml-2"
                    />
                    <vue-fontawesome
                      v-else
                      :icon="['far', 'ban']"
                      class="ml-2"
                    />
                  </v-col>
                  <v-col cols="6" class="text-left">
                    <span
                      :class="
                        doc.urbanLeague.eligibilityRequirements.noSexCrimes
                          ? ' font-weight-bold'
                          : ''
                      "
                      >No sex crimes (unless prostitution)</span
                    >
                    <vue-fontawesome
                      v-if="doc.urbanLeague.eligibilityRequirements.noSexCrimes"
                      :icon="['far', 'check']"
                      class="ml-2"
                    />
                    <vue-fontawesome
                      v-else
                      :icon="['far', 'ban']"
                      class="ml-2"
                    />
                  </v-col>
                </v-row>
              </td>
            </tr>
            <tr>
              <td colspan="2" :width="leftColWidth">
                <v-row no-gutters>
                  <v-col cols="6" class="text-left">
                    <span
                      :class="
                        doc.urbanLeague.eligibilityRequirements.returnToMetro
                          ? ' font-weight-bold'
                          : ''
                      "
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on"
                            >Return to <abbr title="">Metro Atlanta</abbr></span
                          >
                        </template>
                        <span>Fulton, DeKalb, Cobb, Clayton Counties</span>
                      </v-tooltip>
                    </span>
                    <vue-fontawesome
                      v-if="
                        doc.urbanLeague.eligibilityRequirements.returnToMetro
                      "
                      :icon="['far', 'check']"
                      class="ml-2"
                    />
                    <vue-fontawesome
                      v-else
                      :icon="['far', 'ban']"
                      class="ml-2"
                    />
                  </v-col>
                  <v-col cols="6" class="text-left">
                    <span
                      :class="
                        doc.urbanLeague.eligibilityRequirements.veteran
                          ? ' font-weight-bold'
                          : ''
                      "
                      >Veteran</span
                    >
                    <vue-fontawesome
                      v-if="doc.urbanLeague.eligibilityRequirements.veteran"
                      :icon="['far', 'check']"
                      class="ml-2"
                    />
                    <vue-fontawesome
                      v-else
                      :icon="['far', 'ban']"
                      class="ml-2"
                    />
                  </v-col>
                </v-row>
              </td>
            </tr>
            <tr>
              <td colspan="2" :width="leftColWidth">
                <v-row no-gutters>
                  <v-col cols="6" class="text-left">
                    <span
                      :class="
                        doc.urbanLeague.eligibilityRequirements.workPermit
                          ? ' font-weight-bold'
                          : ''
                      "
                      >Non-citizen with work permit</span
                    >
                    <vue-fontawesome
                      v-if="doc.urbanLeague.eligibilityRequirements.workPermit"
                      :icon="['far', 'check']"
                      class="ml-2"
                    />
                    <vue-fontawesome
                      v-else
                      :icon="['far', 'ban']"
                      class="ml-2"
                    />
                  </v-col>
                </v-row>
              </td>
            </tr>
            <tr>
              <td colspan="2" :width="leftColWidth">
                <h3 class="font-weight-regular">Emergency Assistance</h3>
              </td>
            </tr>

            <tr>
              <td colspan="2" :width="leftColWidth">
                <span
                  v-if="doc.urbanLeague.emergencyAssistance.financial"
                  class="font-weight-bold mr-2"
                  >Financial</span
                >
                <span
                  v-if="doc.urbanLeague.emergencyAssistance.housing"
                  class="font-weight-bold mr-2"
                  >Housing</span
                >
                <span
                  v-if="doc.urbanLeague.emergencyAssistance.food"
                  class="font-weight-bold mr-2"
                  >Food</span
                >
                <span
                  v-if="doc.urbanLeague.emergencyAssistanceOther"
                  class="mr-2"
                  >Other:
                  <b>{{ doc.urbanLeague.emergencyAssistanceOther }}</b></span
                >
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
      <v-col cols="6" sm="12" md="6" lg="6">
        <v-simple-table>
          <tbody>
            <tr>
              <td colspan="2" :width="leftColWidth">
                <h3 class="font-weight-regular">TABE/GAIN/GMADE/GRADE data</h3>
              </td>
            </tr>
            <tr>
              <td colspan="2" :width="leftColWidth">
                <span
                  :class="
                    doc.urbanLeague.gradeData.readingSkills9Above
                      ? ' font-weight-bold'
                      : ''
                  "
                  >Reading skills 9.0 or above at time of enrollment</span
                >
                <vue-fontawesome
                  v-if="doc.urbanLeague.gradeData.readingSkills9Above"
                  :icon="['far', 'check']"
                  class="ml-2"
                />
                <vue-fontawesome v-else :icon="['far', 'ban']" class="ml-2" />
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div
                  v-if="doc.urbanLeague.gradeData.readingSkills9Above"
                  class="ml-4"
                >
                  <span v-if="doc.urbanLeague.gradeData.readingSkillsTestScore">
                    Score
                    <b>{{
                      doc.urbanLeague.gradeData.readingSkillsTestScore
                    }}</b>
                  </span>
                  <span
                    v-if="doc.urbanLeague.gradeData.readingSkillsTestDate"
                    class="ml-2"
                  >
                    Date
                    <b>{{
                      formatDate(
                        doc.urbanLeague.gradeData.readingSkillsTestDate
                      )
                    }}</b>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" :width="leftColWidth">
                <span
                  :class="
                    doc.urbanLeague.gradeData.readingSkills9Above
                      ? ' font-weight-bold'
                      : ''
                  "
                  >Math skills 9.0 or above at time of enrollment</span
                >
                <vue-fontawesome
                  v-if="doc.urbanLeague.gradeData.readingSkills9Above"
                  :icon="['far', 'check']"
                  class="ml-2"
                />
                <vue-fontawesome v-else :icon="['far', 'ban']" class="ml-2" />
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div
                  v-if="doc.urbanLeague.gradeData.mathSkills9Above"
                  class="ml-4"
                >
                  <span v-if="doc.urbanLeague.gradeData.mathSkillsTestScore">
                    Score
                    <b>{{ doc.urbanLeague.gradeData.mathSkillsTestScore }}</b>
                  </span>
                  <span
                    v-if="doc.urbanLeague.gradeData.mathSkillsTestDate"
                    class="ml-2"
                  >
                    Date
                    <b>{{
                      formatDate(doc.urbanLeague.gradeData.mathSkillsTestDate)
                    }}</b>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" :width="leftColWidth">
                <h3 class="font-weight-regular">Wage data</h3>
              </td>
            </tr>
            <tr>
              <td colspan="2" :width="leftColWidth">
                <v-row no-gutters>
                  <v-col cols="6" class="text-left">
                    <span
                      :class="
                        doc.urbanLeague.wageIncomeData.wageIncrease
                          ? ' font-weight-bold'
                          : ''
                      "
                      >Wage increase during participation</span
                    >
                    <vue-fontawesome
                      v-if="doc.urbanLeague.wageIncomeData.wageIncrease"
                      :icon="['far', 'check']"
                      class="ml-2"
                    />
                    <vue-fontawesome
                      v-else
                      :icon="['far', 'ban']"
                      class="ml-2"
                    />
                  </v-col>
                  <v-col cols="6" class="text-left">
                    <span
                      :class="
                        doc.urbanLeague.wageIncomeData.promotion
                          ? ' font-weight-bold'
                          : ''
                      "
                      >Promotion during participation</span
                    >
                    <vue-fontawesome
                      v-if="doc.urbanLeague.wageIncomeData.promotion"
                      :icon="['far', 'check']"
                      class="ml-2"
                    />
                    <vue-fontawesome
                      v-else
                      :icon="['far', 'ban']"
                      class="ml-2"
                    />
                  </v-col>
                </v-row>
              </td>
            </tr>
            <tr>
              <td colspan="2" :width="leftColWidth">
                <v-row no-gutters>
                  <v-col cols="6" class="text-left">
                    <span
                      :class="
                        doc.urbanLeague.wageIncomeData.disciplinaryAction
                          ? ' font-weight-bold'
                          : ''
                      "
                      >Disciplinary action during participation</span
                    >
                    <vue-fontawesome
                      v-if="doc.urbanLeague.wageIncomeData.disciplinaryAction"
                      :icon="['far', 'check']"
                      class="ml-2"
                    />
                    <vue-fontawesome
                      v-else
                      :icon="['far', 'ban']"
                      class="ml-2"
                    />
                  </v-col>
                  <v-col cols="6" class="text-left">
                    <span
                      :class="
                        doc.urbanLeague.wageIncomeData.terminated
                          ? ' font-weight-bold'
                          : ''
                      "
                      >Terminated during participation</span
                    >
                    <vue-fontawesome
                      v-if="doc.urbanLeague.wageIncomeData.terminated"
                      :icon="['far', 'check']"
                      class="ml-2"
                    />
                    <vue-fontawesome
                      v-else
                      :icon="['far', 'ban']"
                      class="ml-2"
                    />
                  </v-col>
                </v-row>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ParticipationService from '@/services/ParticipationService'
import options from '@/config/valueOptions'
// import { DateTime } from 'luxon'
import Snackbar from '@/components/Snackbar.vue'
import sharedMixin from '@/mixins/sharedMixin'

export default {
  name: 'ParticipationUrbanLeague',
  mixins: [sharedMixin],
  components: { Snackbar },
  props: {
    doc: Object,
    canEdit: Boolean
  },
  data() {
    return {
      snackbar: {
        model: false,
        color: '',
        message: '',
        icon: ['fas', 'exclamation-triangle'],
        timeout: 3000
      },
      panel: [0],
      readingSkillsTestDate: '',
      readingSkillsTestDateYMD: '',
      readingSkillsTestDateMDY: '',
      mathSkillsTestDate: '',
      mathSkillsTestDateYMD: '',
      mathSkillsTestDateMDY: '',
      leftColWidth: 130,
      rightColWidth: 150,
      uglaReferrals: options.uglaReferrals,
      enrollmentTracks: options.enrollmentTracks,
      assistances: options.assistances,
      tab: null,
      dialog: false,
      participationDialog: false,
      participationDialogDelete: false,
      citizenIndex: -1,
      docId: this.$route.params.id,
      docEdit: {
        urbanLeague: {
          firstEngagement: '',
          referral: '',
          referralOther: '',
          enrollmentTrack: '',
          eligibilityRequirements: {
            eighteenYears: '',
            convictedAsAdult: '',
            startWithin9months: '',
            noSexCrimes: '',
            returnToMetro: '',
            veteran: '',
            workPermit: ''
          },
          gradeData: {
            readingSkills9Above: '',
            readingSkillsTestScore: '',
            readingSkillsTestDate: '',
            mathSkills9Above: '',
            mathSkillsTestScore: '',
            mathSkillsTestDate: ''
          },
          wageIncomeData: {
            wageIncrease: '',
            promotion: '',
            disciplinaryAction: '',
            terminated: '',
            laidOff: ''
          },
          emergencyAssistance: {
            financial: false,
            housing: false,
            food: false
          },
          emergencyAssistanceOther: '',
          assistances: {}
        }
      },
      rules: [v => !!v || 'This is required']
    }
  },
  watch: {
    participationDialog(val) {
      val || this.closeParticipationDialog()
    },
    participationDialogDelete(val) {
      val || this.closeParticipationDelete()
    }
  },
  mounted() {},
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    isEdit() {
      return this.citizenIndex === -1 ? false : true
    },
    formTitle() {
      return this.engagementIndex === -1
        ? 'New ULGA Information'
        : 'Edit ULGA Information'
    }
  },
  methods: {
    saveDate(date, ref) {
      // console.log(ref)
      if (ref === 'reading') {
        this.readingSkillsTestDateMDY = this.formatDate(date)
      } else if (ref === 'math') {
        this.mathSkillsTestDateMDY = this.formatDate(date)
      }
    },
    // formatDate(date) {
    //   if (!date) return null
    //   const dt = DateTime.fromISO(date)
    //     .toUTC()
    //     .toFormat('MM/dd/yyyy')
    //   return dt
    // },
    // formatDateYMD(date) {
    //   if (!date) return null
    //   const dt = DateTime.fromISO(date)
    //     .toUTC()
    //     .toFormat('yyyy-MM-dd')
    //   return dt
    // },
    clearReferral() {
      if (this.docEdit.urbanLeague.referral != 'Other') {
        this.docEdit.urbanLeague.referralOther = ''
      }
    },
    editParticipation() {
      /*
        we have to set our date formats for users as well as datepickers
      */
      this.readingSkillsTestDateYMD = this.formatDateYMD(
        this.doc.urbanLeague.gradeData.readingSkillsTestDate
      )
      this.readingSkillsTestDateMDY = this.formatDate(
        this.doc.urbanLeague.gradeData.readingSkillsTestDate
      )
      this.mathSkillsTestDateMDY = this.formatDate(
        this.doc.urbanLeague.gradeData.mathSkillsTestDate
      )
      this.mathSkillsTestDateYMD = this.formatDateYMD(
        this.doc.urbanLeague.gradeData.mathSkillsTestDate
      )
      this.docEdit = Object.assign({}, this.doc)
      this.participationDialog = true
    },
    async saveDoc() {
      // console.log('id: ' + this.doc.id)
      // alert('component')
      const data = {
        updatedBy: this.$store.state.user.id,
        urbanLeague: {
          firstEngagement: this.docEdit.urbanLeague.firstEngagement,
          referral: this.docEdit.urbanLeague.referral,
          referralOther: this.docEdit.urbanLeague.referralOther,
          enrollmentTrack: this.docEdit.urbanLeague.enrollmentTrack,
          eligibilityRequirements: {
            eighteenYears: this.docEdit.urbanLeague.eligibilityRequirements
              .eighteenYears,
            convictedAsAdult: this.docEdit.urbanLeague.eligibilityRequirements
              .convictedAsAdult,
            startWithin9months: this.docEdit.urbanLeague.eligibilityRequirements
              .startWithin9months,
            noSexCrimes: this.docEdit.urbanLeague.eligibilityRequirements
              .noSexCrimes,
            returnToMetro: this.docEdit.urbanLeague.eligibilityRequirements
              .returnToMetro,
            veteran: this.docEdit.urbanLeague.eligibilityRequirements.veteran,
            workPermit: this.docEdit.urbanLeague.eligibilityRequirements
              .workPermit
          },
          gradeData: {
            readingSkills9Above: this.docEdit.urbanLeague.gradeData
              .readingSkills9Above,
            readingSkillsTestScore: this.docEdit.urbanLeague.gradeData
              .readingSkillsTestScore,
            readingSkillsTestDate: this.readingSkillsTestDateYMD,
            mathSkills9Above: this.docEdit.urbanLeague.gradeData
              .mathSkills9Above,
            mathSkillsTestScore: this.docEdit.urbanLeague.gradeData
              .mathSkillsTestScore,
            mathSkillsTestDate: this.mathSkillsTestDateYMD
          },
          wageIncomeData: {
            wageIncrease: this.docEdit.urbanLeague.wageIncomeData.wageIncrease,
            promotion: this.docEdit.urbanLeague.wageIncomeData.promotion,
            disciplinaryAction: this.docEdit.urbanLeague.wageIncomeData
              .disciplinaryAction,
            terminated: this.docEdit.urbanLeague.wageIncomeData.terminated,
            laidOff: this.docEdit.urbanLeague.wageIncomeData.laidOff
          },
          emergencyAssistance: {
            financial: this.docEdit.urbanLeague.emergencyAssistance.financial
              ? true
              : false,
            housing: this.docEdit.urbanLeague.emergencyAssistance.housing
              ? true
              : false,
            food: this.docEdit.urbanLeague.emergencyAssistance.food
              ? true
              : false
          },
          emergencyAssistanceOther: this.docEdit.urbanLeague
            .emergencyAssistanceOther
        }
      }
      // console.log(data)
      try {
        const docUpdate = await ParticipationService.update(this.doc.id, data)
        // console.log(docUpdate)
        if (docUpdate.status != 200) {
          return
        }
        Object.assign(this.doc, this.docEdit)
        this.doc.urbanLeague.gradeData.readingSkillsTestDate = this.readingSkillsTestDateYMD
        this.doc.urbanLeague.gradeData.mathSkillsTestDate = this.mathSkillsTestDateYMD
        this.closeParticipationDialog()
        this.snackbar.model = true
        this.snackbar.message = 'Participation updated'
        this.snackbar.color = 'green'
        this.snackbar.icon = ['far', 'check']
      } catch (err) {
        this.snackbar.model = true
        this.snackbar.message = 'Participation problem!'
        this.snackbar.color = 'red'
        this.snackbar.icon = ['fas', 'exclamation-triangle']
        console.log(err)
      }
    },
    closeParticipationDialog() {
      this.participationDialog = false
      // this.$nextTick(() => {
      //   this.citizen = Object.assign({}, this.citizenDefault)
      //   // this.citizenIndex = -1
      // })
    },
    closeParticipationDelete() {
      this.participationDialogDelete = false
      this.$nextTick(() => {
        this.citizen = Object.assign({}, this.citizenDefault)
        this.citizenIndex = -1
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.v-tab {
  font-weight: normal;
  text-transform: none;
  letter-spacing: 0 !important;
}
.citizen-header {
  letter-spacing: -0.5px !important;
}
</style>
