import axios from '../config/api.config'

class ActivityService {
  create(data) {
    return axios.post('/activity', data)
  }
  getActivityByCitizen(id) {
    return axios.get(`/activity/citizen/${id}`)
  }
  delete(id) {
    return axios.delete(`/activity/${id}`)
  }
}

export default new ActivityService()
