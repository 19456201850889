<template>
  <div>
    <snackbar :attr="snackbar" />
    <v-dialog v-model="engagementDialog" max-width="800px">
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeEngagementDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <v-text-field
                    v-model="engagement.engagementName"
                    :rules="rules"
                    required
                    id="engagementName"
                    name="engagementName"
                    label="Activity Name"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <v-menu
                    ref="engagementDate"
                    v-model="engagementDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="engagementDateMDY"
                        :rules="rules"
                        required
                        label="Activity Date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        ><vue-fontawesome
                          :icon="['fas', 'calendar-alt']"
                          slot="prepend-inner"
                          class="mt-1 mr-1"
                        />{{ engagementDateYMD }}</v-text-field
                      >
                    </template>
                    <v-date-picker
                      v-model="engagementDateYMD"
                      @change="saveDate(engagementDateYMD, 'engagement')"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text @click="engagementDate = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.engagementDate.save(engagementDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeEngagementDialog"> Close </v-btn>
          <v-btn
            :disabled="!valid"
            dark
            elevation="0"
            color="primary-btn"
            class="mr-4"
            @click="saveEngagement"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row no-gutters>
      <v-col cols="12">
        <v-btn
          v-if="canEdit"
          small
          dark
          class="primary-btn"
          elevation="0"
          @click="engagementDialog = true"
        >
          <vue-fontawesome :icon="['far', 'plus']" class="mr-1" />Add Engagement
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="engagements"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-dialog v-model="engagementDialogDelete" width="500">
              <v-card>
                <v-card-title class="headline">
                  <span class="headline">
                    <vue-fontawesome icon="exclamation-triangle" class="mr-2" />
                    Delete Engagement?</span
                  >
                </v-card-title>
                <v-card-text>
                  This engagement record will be permenantly deleted.
                  <!-- <div class="text--disabled body-2">{{ engagementId }}</div> -->
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="engagementDialogDelete = false">
                    No
                  </v-btn>
                  <v-btn text @click="deleteEngagementConfirm"> Yes </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:item.lastName="{ item }">
            {{ item.firstName + ' ' + item.lastName }}
          </template>
          <template v-slot:item.engagementDate="{ item }">
            {{ formatDate(item.engagementDate) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn icon small @click="editEngagement(item)">
              <vue-fontawesome
                small
                :icon="['fas', 'pencil-alt']"
                class="mr-1"
              />
            </v-btn>
            <v-btn icon small @click="deleteEngagement(item, item.id)">
              <vue-fontawesome :icon="['fas', 'trash']" />
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EngagementService from '@/services/EngagementService'
import Snackbar from '@/components/Snackbar.vue'
import { DateTime } from 'luxon'

export default {
  name: 'ParticipationEngagement',
  components: {
    Snackbar
  },
  props: {
    doc: Object,
    options: Array,
    canEdit: Boolean
  },
  data() {
    return {
      snackbar: {
        model: false,
        color: '',
        message: '',
        icon: ['fas', 'exclamation-triangle'],
        timeout: 3000
      },
      dialog: false,
      valid: false,
      engagementDate: '',
      engagementDateYMD: '',
      engagementDateMDY: '',

      engagementDialog: false,
      engagementDialogDelete: false,
      engagementIndex: -1,
      engagementId: '',
      engagements: [],
      engagement: {
        id: '',
        engagementName: '',
        engagementDate: ''
      },
      engagementDefault: {
        id: '',
        engagementName: '',
        engagementDate: ''
      },
      rules: [v => !!v || 'This is required'],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'engagementName'
        },
        {
          text: 'Date',
          align: 'start',
          value: 'engagementDate'
        },
        {
          text: 'Actions',
          align: 'end',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  watch: {
    engagementDialogDelete(val) {
      val || this.closeEngagementDelete()
    }
  },
  created() {
    // console.log(this.doc)
    EngagementService.getByParticipation(this.doc.id)
      .then(response => {
        // let token = localStorage.getItem("jwt");
        // let decoded = VueJwtDecode.decode(token);
        // this.engagement = decoded;
        this.engagements = response.data
        // console.log(response.data)
      })
      .catch(error => {
        console.log('We had an error: ' + error)
      })
  },
  mounted() {},
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    formTitle() {
      return this.engagementIndex === -1
        ? 'New Engagement Activity'
        : 'Edit Engagement Activity'
    },
    isEdit() {
      return this.engagementIndex === -1 ? false : true
    },
    engagementCount() {
      return this.engagements.length
    }
  },
  methods: {
    reset() {
      this.valid = false
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    validateForm() {
      this.$refs.form.validate()
    },
    saveDate(date, ref) {
      // console.log(ref)
      if (ref === 'engagement') {
        this.engagementDateMDY = this.formatDate(date)
      } else {
        this.dateEndedMDY = this.formatDate(date)
      }
    },
    formatDate(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date)
        .toUTC()
        .toFormat('MM/dd/yyyy')
      return dt
    },
    formatDateYMD(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date)
        .toUTC()
        .toFormat('yyyy-MM-dd')
      return dt
    },
    sumField() {
      // sum data in give key (property)
      return this.engagements.reduce(
        (a, b) => a + Number(b['frequencyTotal'] || 0),
        0
      )
    },
    clearFrequencyOther() {
      if (this.engagement.frequency != 'Other') {
        this.engagement.frequencyOther = ''
      }
    },
    editEngagement(item) {
      console.log('get')
      // alert(id)
      // populate dates correctly...

      this.engagementIndex = this.engagements.indexOf(item)
      this.engagement = Object.assign({}, item)
      // console.log(item.id)
      this.engagementDateYMD = this.formatDateYMD(
        this.engagement.engagementDate
      )
      this.engagementDateMDY = this.formatDate(this.engagement.engagementDate)
      this.engagementId = item.id
      this.engagementDialog = true
    },
    deleteEngagement(item, id) {
      // alert(id)
      this.engagementIndex = this.engagements.indexOf(item)
      this.engagement = Object.assign({}, item)
      this.engagementId = id
      this.engagementDialogDelete = true
    },
    deleteEngagementConfirm() {
      const id = this.engagementId
      // alert(id)
      EngagementService.delete(id, this.doc.id)
        .then(response => {
          // console.log(response)
          this.engagements.splice(this.engagementIndex, 1)
          this.closeEngagementDelete()
          this.snackbar.model = true
          this.snackbar.message = 'Engagement deleted'
          this.snackbar.color = 'green'
          this.snackbar.icon = ['far', 'check']
          return response
        })
        .catch(e => {
          this.snackbar.model = true
          this.snackbar.message = 'Engagement not deleted'
          this.snackbar.color = 'red'
          this.snackbar.icon = ['fas', 'exclamation-triangle']
          console.log(e)
        })
    },
    async saveEngagement() {
      const data = {
        engagementName: this.engagement.engagementName,
        engagementDate: this.engagementDateYMD,
        participation: this.doc.id,
        citizen: this.doc.citizen.id,
        agency: this.doc.agency.id,
        agencyAbbr: this.doc.agencyAbbr
      }
      this.engagement.engagementDate = this.engagementDateYMD
      if (this.engagementIndex > -1) {
        // alert('updated')
        // update
        data.updatedBy = this.$store.state.user.id

        Object.assign(this.engagements[this.engagementIndex], this.engagement)
        EngagementService.update(this.engagement.id, data)
          .then(() => {
            this.closeEngagementDialog()
            this.snackbar.model = true
            this.snackbar.message = 'Engagement updated'
            this.snackbar.color = 'green'
            this.snackbar.icon = ['far', 'check']
          })
          .catch(e => {
            this.closeEngagementDialog()
            this.snackbar.model = true
            this.snackbar.message = 'Engagement error'
            this.snackbar.color = 'red'
            this.snackbar.icon = ['fas', 'exclamation-triangle']
            console.log(e)
          })
      } else {
        data.createdBy = this.$store.state.user.id
        this.saveNewEngagement(data)
      }
    },
    saveNewEngagement(engagementData) {
      EngagementService.create(engagementData)
        .then(response => {
          const newId = response.data.id
          // console.log(newId)
          const newEngagement = Object.assign(this.engagement)
          newEngagement.id = newId
          // const newEngagement = Object.assign({ id: newId }, this.engagement)
          // console.log(newEngagement)
          this.engagements.push(newEngagement)
          this.closeEngagementDialog()
          this.snackbar.model = true
          this.snackbar.message = 'User created'
          this.snackbar.color = 'green'
          this.snackbar.icon = ['far', 'check']
          console.log(
            'Created new engagement successfully! ' + response.data.id
          )
          // this.submitted = true
        })
        .catch(err => {
          console.log(err)
        })
    },
    closeEngagementDialog() {
      this.engagementDialog = false
      // we need to clear date fields if they were set
      this.engagementDateMDY = null
      this.engagementDateYMD = null
      this.$nextTick(() => {
        this.engagement = Object.assign({}, this.engagementDefault)
        this.engagementIndex = -1
      })
    },
    closeEngagementDelete() {
      this.engagementDialogDelete = false
      this.$nextTick(() => {
        this.engagement = Object.assign({}, this.engagementDefault)
        this.engagementIndex = -1
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg,
.disabled-btn {
  background-color: #aaaaaa !important;
}
</style>
