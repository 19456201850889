<template>
  <v-container class="mt-4">
    <snackbar :attr="snackbar" />
    <v-row>
      <v-col cols="12">
        <h2 class="font-weight-black citizen-header">
          {{ citizen.fullName }}
          <router-link :to="`/citizen/${citizen.id}`">
            <v-btn text
              >Profile<vue-fontawesome
                :icon="['far', 'chevron-right']"
                class="ml-2"
            /></v-btn>
          </router-link>
        </h2>
      </v-col>
      <v-col cols="12">
        <span class="text--secondary">{{ participation.agency.name }}</span>
        :
        <span class="font-weight-bold ml-2">{{
          participation.description
        }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-expansion-panels multiple v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header v-slot="">
              <v-row no-gutters>
                <v-col cols="12">Referral Information</v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <participation-referral :doc="participation" :canEdit="canEdit" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="participation.agencyAbbr == 'cor'">
            <v-expansion-panel-header v-slot="">
              <v-row no-gutters>
                <v-col cols="12">TYRO Enrollment</v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <participation-cor :doc="participation" :canEdit="canEdit" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="participation.agencyAbbr == 'cor'">
            <v-expansion-panel-header v-slot="">
              <v-row no-gutters>
                <v-col cols="12">Employment</v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <participation-employment
                :doc="participation"
                :canEdit="canEdit"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="participation.agencyAbbr == 'oaa'">
            <v-expansion-panel-header v-slot="">
              <v-row no-gutters>
                <v-col cols="12">Mentors</v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <participation-mentor
                :doc="participation"
                :options="options.mentorFrequency"
                :canEdit="canEdit"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="participation.agencyAbbr == 'oaa'">
            <v-expansion-panel-header v-slot="">
              <v-row no-gutters>
                <v-col cols="12">Forums</v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <participation-forum :doc="participation" :canEdit="canEdit" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="participation.agencyAbbr == 'ulga'">
            <v-expansion-panel-header v-slot="">
              <v-row no-gutters>
                <v-col cols="12">Engagement Activities</v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <participation-engagement
                :doc="participation"
                :canEdit="canEdit"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="participation.agencyAbbr == 'ulga'">
            <v-expansion-panel-header v-slot="">
              <v-row no-gutters>
                <v-col cols="12">Urban League</v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <participation-urban-league
                :doc="participation"
                :canEdit="canEdit"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header v-slot="">
              <v-row no-gutters>
                <v-col cols="12">Recidivism</v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <participation-recidivism
                :doc="participation"
                :canEdit="canEdit"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-right caption text--secondary">
        Created by
        <b>{{ participation.createdBy.fullName }}</b>
        on <b> {{ formatDateTime(participation.createdAt) }}</b>
        <v-divider vertical class="mx-4"></v-divider>
        Updated by
        <b>{{ participation.updatedBy.fullName }}</b>
        on <b> {{ formatDateTime(participation.updatedAt) }}</b>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import options from '@/config/valueOptions'
import ParticipationService from '@/services/ParticipationService'
import ParticipationReferral from '@/components/participation/ParticipationReferral'
import ParticipationCor from '@/components/participation/ParticipationCor'
import ParticipationEmployment from '@/components/participation/ParticipationEmployment'
import ParticipationRecidivism from '@/components/participation/ParticipationRecidivism.vue'
import ParticipationMentor from '@/components/participation/ParticipationMentor.vue'
import ParticipationForum from '@/components/participation/ParticipationForum.vue'
import ParticipationEngagement from '@/components/participation/ParticipationEngagement.vue'
import ParticipationUrbanLeague from '@/components/participation/ParticipationUrbanLeague.vue'
import Snackbar from '@/components/Snackbar.vue'
import sharedMixin from '@/mixins/sharedMixin.js'

// import { DateTime } from 'luxon'

export default {
  name: 'Participation',
  mixins: [sharedMixin],
  components: {
    Snackbar,
    ParticipationReferral,
    ParticipationCor,
    ParticipationEmployment,
    ParticipationRecidivism,
    ParticipationMentor,
    ParticipationForum,
    ParticipationEngagement,
    ParticipationUrbanLeague
  },
  data() {
    return {
      panel: [],
      snackbar: {
        model: false,
        color: '',
        message: '',
        icon: ['fas', 'exclamation-triangle'],
        timeout: 3000
      },
      options: options,
      citizen: {
        firstName: '',
        lastName: ''
      },
      participation: {
        agency: '',
        agencyAbbr: '',
        createdBy: '',
        updatedBy: '',
        description: '',
        referralData: {
          referralDate: '',
          intakeDate: '',
          startDate: '',
          endDate: '',
          enrollmentDate: '',
          orientationDate: '',
          reasonEnded: '',
          reasonForDismissal: '',
          reasonForDismissalOther: '',
          intakeStaff: '',
          qaReviewStaff: '',
          qaReviewDate: ''
        },
        enrolledInCOR: '',
        CORenrollmentDate: '',
        CORcompletionDate: '',
        enrolledInWIH: '',
        WIHenrollmentDate: '',
        WIHcompletionDate: '',
        employment: {
          priorToEnrollment: '',
          afterEnrollment: '',
          startStatus: '',
          startAverageHoursPerWeek: '',
          startAverageWagePerHour: '',
          endStatus: '',
          endAverageHoursPerWeek: '',
          endAverageWagePerHour: '',
          endStatus6months: '',
          endStatus12months: ''
        },
        recidivism: {
          chargedViolation: '',
          chargedViolationDate: '',
          violationDescription: '',
          chargedOffence: '',
          chargedOffenseDate: '',
          offenseDescription: '',
          returnedToConfinement: '',
          confinementDate: '',
          confinementDescription: ''
        },
        urbanLeague: {
          firstEngagement: '',
          referral: '',
          referralOther: '',
          enrollmentTrack: '',
          eligibilityRequirements: {
            eighteenYears: '',
            convictedAsAdult: '',
            startWithin9months: '',
            noSexCrimes: '',
            returnToMetro: '',
            veteran: '',
            workPermit: ''
          },
          gradeData: {
            readingSkills9Above: '',
            readingSkillsTestScore: '',
            readingSkillsTestDate: '',
            mathSkills9Above: '',
            mathSkillsTestScore: '',
            mathSkillsTestDate: ''
          },
          wageIncomeData: {
            wageIncrease: '',
            promotion: '',
            disciplinaryAction: '',
            terminated: '',
            laidOff: ''
          },
          emergencyAssistance: {
            financial: false,
            housing: false,
            food: false
          },
          emergencyAssistanceOther: ''
        }
      },
      citizenId: this.$route.params.id,
      // citizen: {},
      rules: [v => !!v || 'This is required']
    }
  },
  watch: {
    citizenDialog(val) {
      // this.isEdit
      val || this.closeCitizenDialog()
    },
    citizenDialogDelete(val) {
      val || this.closeCitizenDelete()
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },
  created() {
    // first ditch if they aren't with this agency
    // if (this.$store.state.user.agency.abbr != 'ars') {

    // }
    // if (this.$route.params.agency != this.$store.state.user.agency.abbr) {
    //   this.$router.go(-1)
    //   // this.$router.push('/')
    // }
    // CitizenService.getSimple(this.$route.params.id)
    //   .then(response => {
    //     // this.organization = response.data
    //     this.citizen = response.data
    //     // console.log(response.data)
    //   })
    //   .catch(error => {
    //     console.log('We had an error: ' + error)
    //   })
    ParticipationService.get(this.$route.params.id)
      .then(response => {
        // this.organization = response.data
        this.participation = response.data
        this.citizen = response.data.citizen
        // console.log(response.data.citizen.fullName)
        // console.log('participation: ' + this.participation.agency.id)
        // console.log('state: ' + this.$store.state.agency.id)
        // console.log(this.participation)
      })
      .catch(error => {
        this.snackbar.model = true
        this.snackbar.message = 'No participation found'
        this.snackbar.color = 'red'
        this.snackbar.icon = ['fas', 'exclamation-triangle']
        this.redirect = setTimeout(
          function() {
            // this.$router.go(-1)
            this.$router.push({ path: '/citizens' })
          }.bind(this),
          4000
        )
        console.log('We had an error: ' + error)
      })
  },
  mounted() {},
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    isAdmin() {
      return this.$store.state.user.isAdmin || false
    },
    formTitle() {
      return this.citizenIndex === -1 ? 'New Citizen' : 'Edit Citizen'
    },
    isEdit() {
      return this.citizenIndex === -1 ? false : true
    },
    agencyAbbr() {
      return this.$store.state.agency.abbr || null
    },
    canEdit() {
      return this.participation.agency.id == this.$store.state.agency.id ||
        this.isAdmin
        ? true
        : false
    }
  },
  methods: {
    // formatDate(date) {
    //   if (!date) return null
    //   const dt = DateTime.fromISO(date)
    //     .toLocal()
    //     .toFormat('MM/dd/yyyy')
    //   return dt
    // },
    // formatDateTime(date) {
    //   if (!date) return null
    //   const dt = DateTime.fromISO(date)
    //     .toLocal()
    //     .toFormat('MM/dd/yyyy hh:mm a')
    //   return dt
    // },
    save(date) {
      this.$refs.dateMenu.save(date)
    }
  }
}
</script>
<style lang="scss" scoped>
.v-tab {
  font-weight: normal;
  text-transform: none;
  letter-spacing: 0 !important;
}
.citizen-header {
  letter-spacing: -0.5px !important;
}
</style>
