import axios from '../config/api.config'

class MentorService {
  // getAll() {
  //   return axios.get('/mentors')
  // }

  get(id) {
    return axios.get(`/mentors/${id}`)
  }
  getByParticipation(id) {
    return axios.get(`/mentors/participation/${id}`)
  }

  getByAgency(id, agency) {
    return axios.get(`/mentors/citizen/${id}/agency/${agency}`)
  }

  create(data) {
    return axios.post('/mentors', data)
  }

  update(id, data) {
    return axios.put(`/mentors/${id}`, data)
  }

  delete(id, participationId) {
    return axios.delete(`/mentors/${id}/${participationId}`)
  }

  // deleteAll() {
  //   return axios.delete(`/mentors`)
  // }

  findByTitle(name) {
    return axios.get(`/mentors?name=${name}`)
  }
}

export default new MentorService()
