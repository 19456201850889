<template>
  <div>
    <snackbar :attr="snackbar" />
    <v-dialog v-model="participationDialog" max-width="800px">
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title>Recidivism</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeParticipationDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-checkbox
                v-model="docEdit.recidivism.chargedViolation"
                @change="clearRecidivism('violation')"
                label="Charged with a technical violation of probation/parole during participation"
                id="chargedViolation"
                name="chargedViolation"
                hide-details="auto"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-menu
                ref="chargedViolationDate"
                v-model="chargedViolationDate"
                :close-on-content-click="false"
                :disabled="!docEdit.recidivism.chargedViolation"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="chargedViolationDateMDY"
                    :disabled="!docEdit.recidivism.chargedViolation"
                    label="Violation Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    ><vue-fontawesome
                      :icon="['fas', 'calendar-alt']"
                      slot="prepend-inner"
                      class="mt-1 mr-1"
                    />{{ chargedViolationDateYMD }}</v-text-field
                  >
                </template>
                <v-date-picker
                  v-model="chargedViolationDateYMD"
                  @change="saveDate(chargedViolationDateYMD, 'violation')"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="chargedViolationDate = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs.chargedViolationDate.save(chargedViolationDate)
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="9" lg="9">
              <v-text-field
                v-model="docEdit.recidivism.violationDescription"
                :disabled="!docEdit.recidivism.chargedViolation"
                id="violationDescription"
                name="violationDescription"
                label="Description"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-checkbox
                v-model="docEdit.recidivism.chargedOffense"
                @change="clearRecidivism('offense')"
                label="Charged with a new offense during participation"
                id="chargedOffense"
                name="chargedOffense"
                hide-details="auto"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-menu
                ref="chargedOffenseDate"
                v-model="chargedOffenseDate"
                :disabled="!docEdit.recidivism.chargedOffense"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="chargedOffenseDateMDY"
                    :disabled="!docEdit.recidivism.chargedOffense"
                    label="Offense Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    ><vue-fontawesome
                      :icon="['fas', 'calendar-alt']"
                      slot="prepend-inner"
                      class="mt-1 mr-1"
                    />{{ chargedOffenseDateYMD }}</v-text-field
                  >
                </template>
                <v-date-picker
                  v-model="chargedOffenseDateYMD"
                  @change="saveDate(chargedOffenseDateYMD, 'offense')"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="chargedOffenseDate = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.chargedOffenseDate.save(chargedOffenseDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="9" lg="9">
              <v-text-field
                v-model="docEdit.recidivism.offenseDescription"
                id="offenseDescription"
                :disabled="!docEdit.recidivism.chargedOffense"
                name="offenseDescription"
                label="Description"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-checkbox
                v-model="docEdit.recidivism.returnedToConfinement"
                @change="clearRecidivism('confinement')"
                id="returnedToConfinement"
                name="returnedToConfinement"
                hide-details="auto"
              >
                <template v-slot:label>
                  <div>Returned to confinement <b>during</b> participation</div>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-menu
                ref="confinementDate"
                v-model="confinementDate"
                :disabled="!docEdit.recidivism.returnedToConfinement"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="confinementDateMDY"
                    label="Confinement Date"
                    :disabled="!docEdit.recidivism.returnedToConfinement"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    ><vue-fontawesome
                      :icon="['fas', 'calendar-alt']"
                      slot="prepend-inner"
                      class="mt-1 mr-1"
                    />{{ confinementDateYMD }}</v-text-field
                  >
                </template>
                <v-date-picker
                  v-model="confinementDateYMD"
                  @change="saveDate(confinementDateYMD, 'confinement')"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="confinementDate = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.confinementDate.save(confinementDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="9" lg="9">
              <v-text-field
                v-model="docEdit.recidivism.confinementDescription"
                :disabled="!docEdit.recidivism.returnedToConfinement"
                id="confinementDescription"
                name="confinementDescription"
                label="Description"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="docEdit.recidivism.typeOfConfinement"
                @change="clearConfinementOther"
                :disabled="!docEdit.recidivism.returnedToConfinement"
                clearable
                :items="typeOfConfinements"
                label="Type of Confinement"
                hide-details="auto"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <v-text-field
                v-model="docEdit.recidivism.typeOfConfinementOther"
                :disabled="docEdit.recidivism.typeOfConfinement != 'Other'"
                id="typeOfConfinementOther"
                name="typeOfConfinementOther"
                label="Other"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-checkbox
                v-model="docEdit.recidivism.returnedToConfinementPost"
                @change="clearRecidivism('confinementPost')"
                id="returnedToConfinementPost"
                name="returnedToConfinementPost"
                hide-details="auto"
              >
                <template v-slot:label>
                  <div>Returned to confinement <b>after</b> participation</div>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-menu
                ref="confinementPostDate"
                :disabled="!docEdit.recidivism.returnedToConfinementPost"
                v-model="confinementPostDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="confinementPostDateMDY"
                    :disabled="!docEdit.recidivism.returnedToConfinementPost"
                    label="Post Confinement Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    ><vue-fontawesome
                      :icon="['fas', 'calendar-alt']"
                      slot="prepend-inner"
                      class="mt-1 mr-1"
                    />{{ confinementPostDateYMD }}</v-text-field
                  >
                </template>
                <v-date-picker
                  v-model="confinementPostDateYMD"
                  @change="saveDate(confinementPostDateYMD, 'confinementPost')"
                  :disabled="!docEdit.recidivism.returnedToConfinementPost"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="confinementPostDate = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.confinementPostDate.save(confinementPostDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="9" lg="9">
              <v-text-field
                v-model="docEdit.recidivism.confinementDescriptionPost"
                :disabled="!docEdit.recidivism.returnedToConfinementPost"
                id="confinementDescriptionPost"
                name="confinementDescriptionPost"
                label="Description"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="docEdit.recidivism.typeOfConfinementPost"
                @change="clearConfinementPostOther"
                :disabled="!docEdit.recidivism.returnedToConfinementPost"
                clearable
                :items="typeOfConfinements"
                label="Type of Confinement"
                hide-details="auto"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <v-text-field
                v-model="docEdit.recidivism.typeOfConfinementPostOther"
                :disabled="docEdit.recidivism.typeOfConfinementPost != 'Other'"
                id="typeOfConfinementPostOther"
                name="typeOfConfinementPostOther"
                label="Other"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeParticipationDialog"> Close </v-btn>
          <v-btn elevation="0" color="primary" class="mr-4" @click="saveDoc">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row no-gutters>
      <v-col cols="12" class="d-flex flex-row">
        <v-btn
          v-if="canEdit"
          small
          dark
          class="primary-btn"
          elevation="0"
          @click="editParticipation()"
        >
          <vue-fontawesome :icon="['fas', 'pencil-alt']" class="mr-1" />
          Edit
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="12" md="6" lg="6">
        <v-simple-table>
          <tbody>
            <tr>
              <td colspan="2" :width="leftColWidth">
                <span
                  :class="
                    doc.recidivism.chargedViolation ? 'font-weight-bold' : ''
                  "
                  >Charged with a technical violation of probation/parole during
                  participation</span
                >
                <vue-fontawesome
                  v-if="doc.recidivism.chargedViolation"
                  :icon="['far', 'check']"
                  class="ml-2"
                />
                <vue-fontawesome v-else :icon="['far', 'ban']" class="ml-2" />
              </td>
            </tr>
            <tr>
              <td colspan="2" :width="leftColWidth">
                <div v-if="doc.recidivism.chargedViolation" class="ml-4">
                  <span v-if="doc.recidivism.chargedViolationDate">
                    Date
                    <b>{{ formatDate(doc.recidivism.chargedViolationDate) }}</b>
                  </span>
                  <span v-if="doc.recidivism.violationDescription" class="ml-2">
                    Description
                    <b>{{ doc.recidivism.violationDescription }}</b>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" :width="leftColWidth">
                <span
                  :class="
                    doc.recidivism.chargedOffense ? 'font-weight-bold' : ''
                  "
                  >Charged with a new offense during participation</span
                >
                <vue-fontawesome
                  v-if="doc.recidivism.chargedOffense"
                  :icon="['far', 'check']"
                  class="ml-2"
                />
                <vue-fontawesome v-else :icon="['far', 'ban']" class="ml-2" />
              </td>
            </tr>
            <tr>
              <td colspan="2" :width="leftColWidth">
                <div v-if="doc.recidivism.chargedOffense" class="ml-4">
                  <span v-if="doc.recidivism.chargedOffenseDate">
                    Date
                    <b>{{ formatDate(doc.recidivism.chargedOffenseDate) }}</b>
                  </span>
                  <span v-if="doc.recidivism.offenseDescription" class="ml-2">
                    Description
                    <b>{{ doc.recidivism.offenseDescription }}</b>
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
      <v-col cols="6" sm="12" md="6" lg="6">
        <v-simple-table>
          <tbody>
            <tr>
              <td colspan="2" :width="leftColWidth">
                <span
                  :class="
                    doc.recidivism.returnedToConfinement
                      ? 'font-weight-bold'
                      : ''
                  "
                  >Charged with a technical violation of probation/parole during
                  participation</span
                >
                <vue-fontawesome
                  v-if="doc.recidivism.returnedToConfinement"
                  :icon="['far', 'check']"
                  class="ml-2"
                />
                <vue-fontawesome v-else :icon="['far', 'ban']" class="ml-2" />
              </td>
            </tr>
            <tr>
              <td colspan="2" :width="leftColWidth">
                <div v-if="doc.recidivism.returnedToConfinement" class="ml-4">
                  <span v-if="doc.recidivism.confinementDate">
                    Date
                    <b>{{ formatDate(doc.recidivism.confinementDate) }}</b>
                  </span>
                  <span
                    v-if="doc.recidivism.confinementDescription"
                    class="ml-2"
                  >
                    Description
                    <b>{{ doc.recidivism.confinementDescription }}</b>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" :width="leftColWidth">
                <span
                  :class="
                    doc.recidivism.returnedToConfinementPost
                      ? 'font-weight-bold'
                      : ''
                  "
                  >Charged with a technical violation of probation/parole during
                  participation</span
                >
                <vue-fontawesome
                  v-if="doc.recidivism.returnedToConfinementPost"
                  :icon="['far', 'check']"
                  class="ml-2"
                />
                <vue-fontawesome v-else :icon="['far', 'ban']" class="ml-2" />
              </td>
            </tr>
            <tr>
              <td colspan="2" :width="leftColWidth">
                <div
                  v-if="doc.recidivism.returnedToConfinementPost"
                  class="ml-4"
                >
                  <span v-if="doc.recidivism.confinementPostDate">
                    Date
                    <b>{{ formatDate(doc.recidivism.confinementPostDate) }}</b>
                  </span>
                  <span
                    v-if="doc.recidivism.confinementDescriptionPost"
                    class="ml-2"
                  >
                    Description
                    <b>{{ doc.recidivism.confinementDescriptionPost }}</b>
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ParticipationService from '@/services/ParticipationService'
import ActivityService from '@/services/ActivityService'
import options from '@/config/valueOptions'
import { DateTime } from 'luxon'
import Snackbar from '@/components/Snackbar.vue'

export default {
  name: 'ParticipationRecidivism',
  components: { Snackbar },
  props: {
    doc: Object,
    canEdit: Boolean
  },
  data() {
    return {
      snackbar: {
        model: false,
        color: '',
        message: '',
        icon: ['fas', 'exclamation-triangle'],
        timeout: 3000
      },
      panel: [0],
      chargedViolationDate: '',
      chargedViolationDateYMD: '',
      chargedViolationDateMDY: '',
      chargedOffenseDate: '',
      chargedOffenseDateYMD: '',
      chargedOffenseDateMDY: '',
      confinementDate: '',
      confinementDateYMD: '',
      confinementDateMDY: '',
      confinementPostDate: '',
      confinementPostDateYMD: '',
      confinementPostDateMDY: '',
      leftColWidth: 150,
      rightColWidth: 150,
      participationEndReasons: options.participationEndReasons,
      reasonForDismissalOptions: options.reasonForDismissalOptions,
      typeOfConfinements: options.typeOfConfinements,
      tab: null,
      dialog: false,
      participationDialog: false,
      participationDialogDelete: false,
      citizenIndex: -1,
      docId: this.$route.params.id,
      docEdit: {
        recidivism: {
          chargedViolation: '',
          chargedViolationDate: '',
          violationDescription: '',
          chargedOffence: '',
          chargedOffenseDate: '',
          offenseDescription: '',
          returnedToConfinement: '',
          confinementDate: '',
          confinementDescription: '',
          typeOfConfinement: '',
          typeOfConfinementOther: '',
          returnedToConfinementPost: '',
          confinementPostDate: '',
          confinementDescriptionPost: '',
          typeOfConfinementPost: '',
          typeOfConfinementPostOther: ''
        }
      },
      rules: [v => !!v || 'This is required']
    }
  },
  watch: {
    participationDialog(val) {
      val || this.closeParticipationDialog()
    },
    participationDialogDelete(val) {
      val || this.closeParticipationDelete()
    }
  },
  mounted() {},
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    isEdit() {
      return this.citizenIndex === -1 ? false : true
    }
    // agencyAbbr() {
    //   return this.$store.state.user.agency.abbr || null
    // }
  },
  methods: {
    saveDate(date, ref) {
      // console.log(ref)
      if (ref === 'violation') {
        this.chargedViolationDateMDY = this.formatDate(date)
      } else if (ref === 'offense') {
        this.chargedOffenseDateMDY = this.formatDate(date)
      } else if (ref === 'confinement') {
        this.confinementDateMDY = this.formatDate(date)
      } else {
        this.confinementPostDateMDY = this.formatDate(date)
      }
    },
    formatDate(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date)
        .toUTC()
        .toFormat('MM/dd/yyyy')
      return dt
    },
    formatDatePicker(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date)
        .toUTC()
        .toFormat('yyyy-MM-dd')
      return dt
    },
    clearRecidivism(type) {
      if (type == 'violation' && !this.docEdit.recidivism.chargedViolation) {
        this.docEdit.recidivism.violationDescription = null
        // if (!this.docEdit.recidivism.chargedViolation) {
        //   // this.docEdit.recidivism.chargedViolationDate = null
        //   // this.docEdit.chargedViolationDateYMD = null
        //   // this.docEdit.chargedViolationDateDMY = null
        // }
      } else if (type == 'offense' && !this.docEdit.recidivism.chargedOffense) {
        this.docEdit.recidivism.offenseDescription = null
      } else if (
        type == 'confinement' &&
        !this.docEdit.recidivism.returnedToConfinement
      ) {
        this.docEdit.recidivism.confinementDescription = null
      } else {
        this.docEdit.recidivism.confinementDescriptionPost = null
      }
    },
    clearConfinementOther() {
      if (this.docEdit.recidivism.typeOfConfinement != 'Other') {
        this.docEdit.recidivism.typeOfConfinementOther = null
      }
    },
    clearConfinementPostOther() {
      if (this.docEdit.recidivism.typeOfConfinementPost != 'Other') {
        this.docEdit.recidivism.typeOfConfinementPostOther = null
      }
    },
    editParticipation() {
      /*
        we have to set our date formats for users as well as datepickers
      */
      this.chargedViolationDateYMD = this.formatDatePicker(
        this.doc.recidivism.chargedViolationDate
      )
      this.chargedViolationDateMDY = this.formatDate(
        this.doc.recidivism.chargedViolationDate
      )
      this.chargedOffenseDateMDY = this.formatDate(
        this.doc.recidivism.chargedOffenseDate
      )
      this.chargedOffenseDateYMD = this.formatDatePicker(
        this.doc.recidivism.chargedOffenseDate
      )
      this.confinementDateMDY = this.formatDate(
        this.doc.recidivism.confinementDate
      )
      this.confinementDateYMD = this.formatDatePicker(
        this.doc.recidivism.confinementDate
      )
      this.confinementPostDateMDY = this.formatDate(
        this.doc.recidivism.confinementPostDate
      )
      this.confinementPostDateYMD = this.formatDatePicker(
        this.doc.recidivism.confinementPostDate
      )
      this.docEdit = Object.assign({}, this.doc)
      this.participationDialog = true
    },
    async saveDoc() {
      console.log('id: ' + this.doc.id)
      // alert('component')
      const data = {
        updatedBy: this.$store.state.user.id,
        recidivism: {
          chargedViolation: this.docEdit.recidivism.chargedViolation,
          chargedViolationDate: this.chargedViolationDateYMD,
          violationDescription: this.docEdit.recidivism.violationDescription,
          chargedOffense: this.docEdit.recidivism.chargedOffense,
          chargedOffenseDate: this.chargedOffenseDateYMD,
          offenseDescription: this.docEdit.recidivism.offenseDescription,
          returnedToConfinement: this.docEdit.recidivism.returnedToConfinement,
          confinementDate: this.confinementDateYMD,
          confinementDescription: this.docEdit.recidivism
            .confinementDescription,
          typeOfConfinement: this.docEdit.recidivism.typeOfConfinement,
          typeOfConfinementOther: this.docEdit.recidivism
            .typeOfConfinementOther,
          returnedToConfinementPost: this.docEdit.recidivism
            .returnedToConfinementPost,
          confinementPostDate: this.confinementPostDateYMD,
          confinementDescriptionPost: this.docEdit.recidivism
            .confinementDescriptionPost,
          typeOfConfinementPost: this.docEdit.recidivism.typeOfConfinementPost,
          typeOfConfinementPostOther: this.docEdit.recidivism
            .typeOfConfinementPostOther
        }
      }
      try {
        const docUpdate = await ParticipationService.update(this.doc.id, data)
        // console.log(docUpdate)
        if (docUpdate.status != 200) {
          return
        }
        /*
          activity log - since it worked, send activity
        */
        const activityData = {
          action: 'Updated recidivism information',
          agency: this.$store.state.agency.id,
          citizen: this.doc.citizen.id,
          citizenName:
            this.doc.citizen.firstName + ' ' + this.doc.citizen.lastName,
          userName:
            this.$store.state.user.firstName +
            ' ' +
            this.$store.state.user.lastName,
          createdBy: this.$store.state.user.id
        }
        const activity = await ActivityService.create(activityData)
        if (activity.status != 200) {
          return
        }
        Object.assign(this.doc, this.docEdit)
        this.doc.recidivism.chargedViolationDate = this.chargedViolationDateYMD
        this.doc.recidivism.chargedOffenseDate = this.chargedOffenseDateYMD
        this.doc.recidivism.confinementDate = this.confinementDateYMD
        this.doc.recidivism.confinementPostDate = this.confinementPostDateYMD
        this.closeParticipationDialog()
        this.snackbar.model = true
        this.snackbar.message = 'Participation updated'
        this.snackbar.color = 'green'
        this.snackbar.icon = ['far', 'check']
      } catch (err) {
        this.snackbar.model = true
        this.snackbar.message = 'Participation problem!'
        this.snackbar.color = 'red'
        this.snackbar.icon = ['fas', 'exclamation-triangle']
        console.log(err)
      }
    },
    closeParticipationDialog() {
      this.participationDialog = false
      // this.$nextTick(() => {
      //   this.citizen = Object.assign({}, this.citizenDefault)
      //   // this.citizenIndex = -1
      // })
    },
    closeParticipationDelete() {
      this.participationDialogDelete = false
      this.$nextTick(() => {
        this.citizen = Object.assign({}, this.citizenDefault)
        this.citizenIndex = -1
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.v-tab {
  font-weight: normal;
  text-transform: none;
  letter-spacing: 0 !important;
}
.citizen-header {
  letter-spacing: -0.5px !important;
}
</style>
