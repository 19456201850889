<template>
  <div>
    <snackbar :attr="snackbar" />
    <v-dialog v-model="participationDialog" max-width="800px">
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title>Employment</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeParticipationDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-checkbox
                v-model="docEdit.employment.priorToEnrollment"
                label="Employed prior to enrollment "
                id="priorToEnrollment"
                name="priorToEnrollment"
                hide-details="auto"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="docEdit.employment.startStatus"
                clearable
                :items="corEmploymentStatus"
                label="Status at time of enrollment"
                hide-details="auto"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="3" lg="3">
              <v-text-field
                v-model="docEdit.employment.startAverageHoursPerWeek"
                id="startAverageHoursPerWeek"
                name="startAverageHoursPerWeek"
                label="Avg hours/week"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-model="docEdit.employment.startAverageWagePerHour"
                prefix="$"
                id="startAverageWagePerHour"
                name="startAverageWagePerHour"
                label="Avg wage/hour"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="docEdit.employment.afterEnrollment"
                label="Employed after enrollment"
                id="afterEnrollment"
                name="afterEnrollment"
                hide-details="auto"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="docEdit.employment.endStatus"
                clearable
                :items="corEmploymentStatus"
                label="Status at end of participation"
                hide-details="auto"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="3" lg="3">
              <v-text-field
                v-model="docEdit.employment.endAverageHoursPerWeek"
                id="endAverageHoursPerWeek"
                name="endAverageHoursPerWeek"
                label="Avg hours/week"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-model="docEdit.employment.endAverageWagePerHour"
                prefix="$"
                id="endAverageWagePerHour"
                name="endAverageWagePerHour"
                label="Avg wage/hour"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <span class="subtitle">Post Participation</span>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="docEdit.employment.endStatus6months"
                clearable
                :items="corEmploymentStatus"
                label="6 months post participation"
                hide-details="auto"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="docEdit.employment.endStatus12months"
                clearable
                :items="corEmploymentStatus"
                label="12 months post participation"
                hide-details="auto"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeParticipationDialog"> Close </v-btn>
          <v-btn elevation="0" color="primary" class="mr-4" @click="saveDoc">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row no-gutters>
      <v-col cols="12" class="d-flex flex-row">
        <v-btn
          v-if="canEdit"
          small
          dark
          class="primary-btn"
          elevation="0"
          @click="editDoc()"
        >
          <vue-fontawesome :icon="['fas', 'pencil-alt']" class="mr-1" />
          Edit
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="12" md="6" lg="6">
        <v-simple-table>
          <tbody>
            <tr>
              <td colspan="2" :width="leftColWidth">
                <span
                  :class="
                    doc.employment.priorToEnrollment ? 'font-weight-bold' : ''
                  "
                  >Employment prior to enrollment</span
                >
                <vue-fontawesome
                  v-if="doc.employment.priorToEnrollment"
                  :icon="['far', 'check']"
                  class="ml-2"
                />
                <vue-fontawesome v-else :icon="['far', 'ban']" class="ml-2" />
              </td>
            </tr>
            <tr v-if="doc.employment.priorToEnrollment">
              <td colspan="2">
                <b>{{ doc.employment.startStatus }}</b>
                <span
                  v-if="doc.employment.startAverageHoursPerWeek"
                  class="ml-4"
                >
                  Avarage Hrs/week:
                  <b>{{ doc.employment.startAverageHoursPerWeek }}</b>
                </span>
                <span
                  v-if="doc.employment.startAverageWagePerHour"
                  class="ml-4"
                >
                  Average Wage/hour:
                  <b
                    >${{
                      toCurrency(doc.employment.startAverageWagePerHour)
                    }}</b
                  >
                </span>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="font-weight-bold" style="line-height:1.5rem">
                  Employment status post participation
                </div>
                <span>
                  6 months:
                  <span
                    v-if="doc.employment.endStatus6months"
                    class="font-weight-bold"
                    >{{ doc.employment.endStatus6months }}
                  </span>
                  <span v-else
                    ><vue-fontawesome :icon="['far', 'ban']" class="ml-1"
                  /></span>
                </span>
                <span class="ml-4">
                  12 months:
                  <span
                    v-if="doc.employment.endStatus12months"
                    class="font-weight-bold"
                    >{{ doc.employment.endStatus12months }}
                  </span>
                  <span v-else
                    ><vue-fontawesome :icon="['far', 'ban']" class="ml-1"
                  /></span>
                </span>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
      <v-col cols="6" sm="12" md="6" lg="6">
        <v-simple-table>
          <tbody>
            <tr>
              <td colspan="2" :width="leftColWidth">
                <span
                  :class="
                    doc.employment.afterEnrollment ? 'font-weight-bold' : ''
                  "
                  >Employment at end of participation</span
                >
                <vue-fontawesome
                  v-if="doc.employment.afterEnrollment"
                  :icon="['far', 'check']"
                  class="ml-2"
                />
                <vue-fontawesome v-else :icon="['far', 'ban']" class="ml-2" />
              </td>
            </tr>
            <tr v-if="doc.employment.afterEnrollment">
              <td colspan="2">
                <b>{{ doc.employment.endStatus }}</b>
                <span v-if="doc.employment.endAverageHoursPerWeek" class="ml-4">
                  Avarage Hrs/week:
                  <b>{{ doc.employment.endAverageHoursPerWeek }}</b>
                </span>
                <span v-if="doc.employment.endAverageWagePerHour" class="ml-4">
                  Average Wage/hour:
                  <b>${{ toCurrency(doc.employment.endAverageWagePerHour) }}</b>
                </span>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ParticipationService from '@/services/ParticipationService'
import ActivityService from '@/services/ActivityService'
import options from '@/config/valueOptions'
import states from '@/config/states'
import { DateTime } from 'luxon'
import Snackbar from '@/components/Snackbar.vue'

export default {
  components: { Snackbar },
  name: 'ParticipationEmployment',
  props: {
    doc: Object,
    canEdit: Boolean
  },
  data() {
    return {
      snackbar: {
        model: false,
        color: '',
        message: '',
        icon: ['fas', 'exclamation-triangle'],
        timeout: 3000
      },
      panel: [0],
      CORenrollmentDate: '',
      CORenrollmentDateYMD: '',
      CORenrollmentDateMDY: '',
      CORcompletionDate: '',
      CORcompletionDateYMD: '',
      CORcompletionDateMDY: '',
      WIHenrollmentDate: '',
      WIHenrollmentDateYMD: '',
      WIHenrollmentDateMDY: '',
      WIHcompletionDate: '',
      WIHcompletionDateYMD: '',
      WIHcompletionDateMDY: '',
      states: states,
      leftColWidth: 150,
      rightColWidth: 150,
      corEmploymentStatus: options.corEmploymentStatus,
      tab: null,
      dialog: false,
      participationDialog: false,
      participationDialogDelete: false,
      citizenIndex: -1,
      editing: {},
      docId: this.$route.params.id,
      docEdit: {
        employment: {
          priorToEnrollment: '',
          afterEnrollment: '',
          startStatus: '',
          endStatus: '',
          endStatus6months: '',
          endStatus12months: ''
        }
      },
      rules: [v => !!v || 'This is required'],
      emailRules: [
        // v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ]
    }
  },
  watch: {
    participationDialog(val) {
      val || this.closeParticipationDialog()
    },
    participationDialogDelete(val) {
      val || this.closeParticipationDelete()
    }
    // date(val) {
    //   if (val) {
    //     // this.dateFormatted = this.formatDate(
    //     //   this.docEdit.employment.CORenrollmentDate
    //     // )
    //     this.dateFormatted = this.formatDate(this.date)
    //   }
    // }
  },
  mounted() {
    // this.docEdit = Object.assign({}, this.doc)
  },
  created() {
    // this.docEdit = { ...this.doc }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    isEdit() {
      return this.citizenIndex === -1 ? false : true
    }
  },
  methods: {
    resetForm() {
      this.docEdit = null
    },
    toCurrency(number) {
      return Number(number).toFixed(2)
    },
    saveDate(date, ref) {
      // console.log(ref)
      if (ref === 'corEnrollment') {
        this.CORenrollmentDateMDY = this.formatDate(date)
      } else if (ref === 'corCompletion') {
        this.CORcompletionDateMDY = this.formatDate(date)
      } else if (ref === 'wihEnrollment') {
        this.WIHenrollmentDateMDY = this.formatDate(date)
      } else {
        this.WIHcompletionDateMDY = this.formatDate(date)
      }
    },
    formatDate(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date)
        .toUTC()
        .toFormat('MM/dd/yyyy')
      return dt
    },
    formatDatePicker(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date)
        .toUTC()
        .toFormat('yyyy-MM-dd')
      return dt
    },
    clearDismissal() {
      if (this.doc.employment.reasonEnded != 'Dismissed') {
        this.doc.employment.reasonForDismissal = null
        this.doc.employment.reasonForDismissalOther = null
      }
    },
    clearDismissalOther() {
      if (this.doc.employment.reasonForDismissalOther != 'Other') {
        this.doc.employment.reasonForDismissalOther = null
      }
    },
    editDoc() {
      /*
        we have to set our date formats for users as well as datepickers
      */
      /*
        assign below so we can edit without reactiveness kicking off
      */
      // this._beforeEditingCache = Object.assign({}, this.doc)
      // this.docEdit = { ...this.doc }
      Object.assign(this.docEdit, this.doc)
      // this.editing = Object.assign({}, this.doc)
      this.participationDialog = true
    },
    async saveDoc() {
      console.log('id: ' + this.doc.id)
      // alert('component')
      const data = {
        updatedBy: this.$store.state.user.id,
        employment: {
          priorToEnrollment: this.docEdit.employment.priorToEnrollment,
          afterEnrollment: this.docEdit.employment.afterEnrollment,
          startStatus: this.docEdit.employment.startStatus,
          startAverageHoursPerWeek: this.docEdit.employment
            .startAverageHoursPerWeek,
          startAverageWagePerHour: this.docEdit.employment
            .startAverageWagePerHour,
          endStatus: this.doc.employment.endStatus,
          endAverageHoursPerWeek: this.docEdit.employment
            .endAverageHoursPerWeek,
          endAverageWagePerHour: this.docEdit.employment.endAverageWagePerHour,
          endStatus6months: this.docEdit.employment.endStatus6months,
          endStatus12months: this.docEdit.employment.endStatus12months
        }
      }
      try {
        const docUpdate = await ParticipationService.update(this.doc.id, data)
        // console.log(docUpdate)
        if (docUpdate.status != 200) {
          return
        }
        /*
          activity log - since it worked, send activity
        */
        const activityData = {
          action: 'Updated employment information',
          agency: this.$store.state.agency.id,
          citizen: this.doc.citizen.id,
          citizenName:
            this.doc.citizen.firstName + ' ' + this.doc.citizen.lastName,
          userName:
            this.$store.state.user.firstName +
            ' ' +
            this.$store.state.user.lastName,
          createdBy: this.$store.state.user.id
        }
        const activity = await ActivityService.create(activityData)
        if (activity.status != 200) {
          return
        }
        // this.doc.employment.endAverageWagePerHour = this.docEdit.employment.endAverageWagePerHour.toFixed(
        //   2
        // )
        Object.assign(this.doc, this.docEdit)
        this.closeParticipationDialog()
        this.snackbar.model = true
        this.snackbar.message = 'Participation updated'
        this.snackbar.color = 'green'
        this.snackbar.icon = ['far', 'check']
      } catch (err) {
        this.snackbar.model = true
        this.snackbar.message = 'Participation problem!'
        this.snackbar.color = 'red'
        this.snackbar.icon = ['fas', 'exclamation-triangle']
        console.log(err)
      }
    },
    closeParticipationDialog() {
      this.participationDialog = false
      // Object.assign(this.doc, this._beforeEditingCache)
      // Exit editing mode
      // this.editing = this._beforeEditingCache = null
      // this.$nextTick(() => {
      //   this.doc = Object.assign({}, this.docEdit)
      // })
    },
    closeParticipationDelete() {
      this.participationDialogDelete = false
      // this.$nextTick(() => {
      //   this.citizen = Object.assign({}, this.citizenDefault)
      //   this.citizenIndex = -1
      // })
    }
  }
}
</script>
<style lang="scss" scoped>
.v-tab {
  font-weight: normal;
  text-transform: none;
  letter-spacing: 0 !important;
}
.citizen-header {
  letter-spacing: -0.5px !important;
}
</style>
