import axios from '../config/api.config'

class ForumService {
  // getAll() {
  //   return axios.get('/forums')
  // }

  get(id) {
    return axios.get(`/forums/${id}`)
  }
  getByParticipation(id) {
    return axios.get(`/forums/participation/${id}`)
  }

  create(data) {
    return axios.post('/forums', data)
  }

  update(id, data) {
    return axios.put(`/forums/${id}`, data)
  }

  delete(id, participationId) {
    return axios.delete(`/forums/${id}/${participationId}`)
  }

  // deleteAll() {
  //   return axios.delete(`/forums`)
  // }

  findByTitle(name) {
    return axios.get(`/forums?name=${name}`)
  }
}

export default new ForumService()
