<template>
  <div>
    <snackbar :attr="snackbar" />
    <v-dialog v-model="participationDialog" max-width="800px">
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title>Referral Information</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeParticipationDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="docEdit.description"
                id="description"
                name="description"
                label="Description (Optional)"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-menu
                ref="referralDate"
                v-model="referralDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="referralDateMDY"
                    label="Referral Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    ><vue-fontawesome
                      :icon="['fas', 'calendar-alt']"
                      slot="prepend-inner"
                      class="mt-1 mr-1"
                    />{{ referralDateYMD }}</v-text-field
                  >
                </template>
                <v-date-picker
                  v-model="referralDateYMD"
                  @change="saveDate(referralDateYMD, 'referral')"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="referralDate = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.referralDate.save(referralDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-menu
                ref="intakeDate"
                v-model="intakeDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="intakeDateMDY"
                    label="Intake Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    ><vue-fontawesome
                      :icon="['fas', 'calendar-alt']"
                      slot="prepend-inner"
                      class="mt-1 mr-1"
                    />{{ intakeDateYMD }}</v-text-field
                  >
                </template>
                <v-date-picker
                  v-model="intakeDateYMD"
                  @change="saveDate(intakeDateYMD, 'intake')"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="intakeDate = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.intakeDate.save(intakeDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-menu
                ref="startDate"
                v-model="startDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDateMDY"
                    label="Participation Start Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    ><vue-fontawesome
                      :icon="['fas', 'calendar-alt']"
                      slot="prepend-inner"
                      class="mt-1 mr-1"
                    />{{ startDateYMD }}</v-text-field
                  >
                </template>
                <v-date-picker
                  v-model="startDateYMD"
                  @change="saveDate(startDateYMD, 'start')"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="startDate = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.startDate.save(startDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-menu
                ref="endDate"
                v-model="endDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDateMDY"
                    label="Participation End Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    ><vue-fontawesome
                      :icon="['fas', 'calendar-alt']"
                      slot="prepend-inner"
                      class="mt-1 mr-1"
                    />{{ endDateYMD }}</v-text-field
                  >
                </template>
                <v-date-picker
                  v-model="endDateYMD"
                  @change="saveDate(endDateYMD, 'end')"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="endDate = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.endDate.save(endDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="docEdit.referralData.reasonEnded"
                @change="clearDismissal"
                :items="participationEndReasons"
                label="Reason Ended Participation"
                hide-details="auto"
                :rules="rules"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="docEdit.referralData.reasonForDismissal"
                @change="clearDismissalOther"
                :items="reasonForDismissalOptions"
                :disabled="docEdit.referralData.reasonEnded != 'Dismissed'"
                label="Reason For Dismissal"
                hide-details="auto"
              >
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="docEdit.referralData.reasonForDismissalOther"
                :disabled="docEdit.referralData.reasonForDismissal != 'Other'"
                id="reasonForDismissalOther"
                name="reasonForDismissalOther"
                label="Other reason for dismissal"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeParticipationDialog"> Close </v-btn>
          <v-btn
            elevation="0"
            class="white--text primary-btn mr-4"
            @click="saveDoc"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row no-gutters>
      <v-col cols="1">
        <v-btn
          v-if="canEdit"
          small
          dark
          class="primary-btn"
          elevation="0"
          @click="editParticipation()"
        >
          <vue-fontawesome :icon="['fas', 'pencil-alt']" class="mr-1" />
          Edit
        </v-btn>
      </v-col>
      <v-col cols="11" class="d-flex flex-row justify">
        <span class="text--secondary mr-2">Description</span>
        <span class="font-weight-black">{{ doc.description }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" sm="12" md="6" lg="3">
        <v-simple-table>
          <tbody>
            <tr>
              <td class="text-right" :width="leftColWidth">Referral Date</td>
              <td class="font-weight-bold">
                {{ formatDate(doc.referralData.referralDate) }}
              </td>
            </tr>
            <tr>
              <td class="text-right" :width="leftColWidth">
                Start Date
              </td>
              <td class="font-weight-bold">
                {{ formatDate(doc.referralData.startDate) }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
      <v-col cols="3" sm="12" md="6" lg="3">
        <v-simple-table>
          <tbody>
            <tr>
              <td class="text-right" :width="leftColWidth">Intake Date</td>
              <td class="font-weight-bold">
                {{ formatDate(doc.referralData.intakeDate) }}
              </td>
            </tr>
            <tr>
              <td class="text-right text-no-wrap" :width="leftColWidth">
                End Date
              </td>
              <td class="font-weight-bold">
                {{ formatDate(doc.referralData.endDate) }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
      <v-col cols="6" sm="12" md="12" lg="6">
        <v-simple-table>
          <tbody>
            <tr>
              <td class="text-right" :width="leftColWidth">Reason Ended</td>
              <td>
                <b>{{ doc.referralData.reasonEnded }}</b>
                <vue-fontawesome
                  v-if="doc.referralData.reasonEnded == 'Graduated'"
                  :icon="['far', 'check']"
                  class="ml-2"
                />
                <span
                  v-if="doc.referralData.reasonForDismissal"
                  class="text--secondary"
                >
                  : {{ doc.referralData.reasonForDismissal }}
                </span>
                <span
                  v-if="doc.referralData.reasonForDismissalOther"
                  class="text--secondary"
                >
                  ({{ doc.referralData.reasonForDismissalOther }})
                </span>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ParticipationService from '@/services/ParticipationService'
import ActivityService from '@/services/ActivityService'
import options from '@/config/valueOptions'
import states from '@/config/states'
import { DateTime } from 'luxon'
import Snackbar from '@/components/Snackbar.vue'

export default {
  components: { Snackbar },
  name: 'Referral',
  props: {
    doc: Object,
    canEdit: Boolean
  },
  data() {
    return {
      snackbar: {
        model: false,
        color: '',
        message: '',
        icon: ['fas', 'exclamation-triangle'],
        timeout: 3000
      },
      panel: [0],
      referralDate: '',
      referralDateYMD: '',
      referralDateMDY: '',
      intakeDate: '',
      intakeDateYMD: '',
      intakeDateMDY: '',
      startDate: '',
      startDateYMD: '',
      startDateMDY: '',
      endDate: '',
      endDateYMD: '',
      endDateMDY: '',
      states: states,
      leftColWidth: 120,
      rightColWidth: 160,
      participationEndReasons: options.participationEndReasons,
      reasonForDismissalOptions: options.reasonForDismissalOptions,
      tab: null,
      dialog: false,
      participationDialog: false,
      participationDialogDelete: false,
      citizenIndex: -1,
      docId: this.$route.params.id,
      docEdit: {
        description: '',
        referralData: {
          // referralDate: null
        }
      },
      rules: [v => !!v || 'This is required'],
      emailRules: [
        // v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ]
    }
  },
  watch: {
    participationDialog(val) {
      val || this.closeParticipationDialog()
    },
    participationDialogDelete(val) {
      val || this.closeParticipationDelete()
    }
    // date(val) {
    //   if (val) {
    //     // this.dateFormatted = this.formatDate(
    //     //   this.docEdit.referralData.referralDate
    //     // )
    //     this.dateFormatted = this.formatDate(this.date)
    //   }
    // }
  },
  mounted() {},
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    }
  },
  methods: {
    saveDate(date, ref) {
      console.log(ref)
      if (ref === 'referral') {
        this.referralDateMDY = this.formatDate(date)
      } else if (ref === 'intake') {
        this.intakeDateMDY = this.formatDate(date)
      } else if (ref === 'start') {
        this.startDateMDY = this.formatDate(date)
      } else {
        this.endDateMDY = this.formatDate(date)
      }
    },
    formatDate(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date)
        .toUTC()
        .toFormat('MM/dd/yyyy')
      return dt
    },
    formatDateYMD(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date)
        .toUTC()
        .toFormat('yyyy-MM-dd')
      return dt
    },
    clearDismissal() {
      if (this.doc.referralData.reasonEnded != 'Dismissed') {
        this.doc.referralData.reasonForDismissal = null
        this.doc.referralData.reasonForDismissalOther = null
      }
    },
    clearDismissalOther() {
      if (this.doc.referralData.reasonForDismissalOther != 'Other') {
        this.doc.referralData.reasonForDismissalOther = null
      }
    },
    editParticipation() {
      this.referralDateYMD = this.formatDateYMD(
        this.doc.referralData.referralDate
      )
      this.referralDateMDY = this.formatDate(this.doc.referralData.referralDate)
      this.intakeDateYMD = this.formatDateYMD(this.doc.referralData.intakeDate)
      this.intakeDateMDY = this.formatDate(this.doc.referralData.intakeDate)
      this.startDateYMD = this.formatDateYMD(this.doc.referralData.startDate)
      this.startDateMDY = this.formatDate(this.doc.referralData.startDate)
      this.endDateYMD = this.formatDateYMD(this.doc.referralData.endDate)
      this.endDateMDY = this.formatDate(this.doc.referralData.endDate)
      this.docEdit = Object.assign({}, this.doc)
      this.participationDialog = true
    },
    async saveDoc() {
      console.log('id: ' + this.doc.id)
      // alert('component')
      const data = {
        updatedBy: this.$store.state.user.id,
        description: this.docEdit.description,
        referralData: {
          referralDate: this.referralDateYMD,
          intakeDate: this.intakeDateYMD,
          startDate: this.startDateYMD,
          endDate: this.endDateYMD,
          reasonEnded: this.docEdit.referralData.reasonEnded,
          reasonForDismissal: this.docEdit.referralData.reasonForDismissal,
          reasonForDismissalOther: this.docEdit.referralData
            .reasonForDismissalOther
          // lastName: this.docEdit.referralData.lastName,
          // phone: this.docEdit.referralData.phone,
          // referringAgency: this.docEdit.referralData.referringAgency,
          // dateEligible: this.eligibleDateFormatted,
          // isEligible: this.docEdit.referralData.isEligible,
          // ineligibleReason: this.docEdit.referralData.ineligibleReason,
          // declinedReason: this.docEdit.referralData.declinedReason
        }
      }
      try {
        const docUpdate = await ParticipationService.update(this.doc.id, data)
        // console.log(docUpdate)
        if (docUpdate.status != 200) {
          return
        }
        /*
          activity log - since it worked, send activity
        */
        const activityData = {
          action: 'Updated referral information',
          agency: this.$store.state.agency.id,
          citizen: this.doc.citizen.id,
          citizenName:
            this.doc.citizen.firstName + ' ' + this.doc.citizen.lastName,
          userName:
            this.$store.state.user.firstName +
            ' ' +
            this.$store.state.user.lastName,
          createdBy: this.$store.state.user.id
        }
        const activity = await ActivityService.create(activityData)
        if (activity.status != 200) {
          return
        }

        Object.assign(this.doc, this.docEdit)
        this.doc.referralData.referralDate = this.referralDateYMD
        this.doc.referralData.intakeDate = this.intakeDateYMD
        this.doc.referralData.startDate = this.startDateYMD
        this.doc.referralData.endDate = this.endDateYMD
        this.closeParticipationDialog()
        this.snackbar.model = true
        this.snackbar.message = 'Participation updated'
        this.snackbar.color = 'green'
        this.snackbar.icon = ['far', 'check']
      } catch (err) {
        this.snackbar.model = true
        this.snackbar.message = 'Participation problem!'
        this.snackbar.color = 'red'
        this.snackbar.icon = ['fas', 'exclamation-triangle']
        console.log(err)
      }
    },
    closeParticipationDialog() {
      this.participationDialog = false
      // this.$nextTick(() => {
      //   this.citizen = Object.assign({}, this.citizenDefault)
      //   // this.citizenIndex = -1
      // })
    },
    closeParticipationDelete() {
      this.participationDialogDelete = false
      this.$nextTick(() => {
        this.citizen = Object.assign({}, this.citizenDefault)
        this.citizenIndex = -1
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.v-tab {
  font-weight: normal;
  text-transform: none;
  letter-spacing: 0 !important;
}
.citizen-header {
  letter-spacing: -0.5px !important;
}
</style>
