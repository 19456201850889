import axios from '../config/api.config'

class EngagementService {
  // getAll() {
  //   return axios.get('/engagements')
  // }

  get(id) {
    return axios.get(`/engagements/${id}`)
  }
  getByParticipation(id) {
    return axios.get(`/engagements/participation/${id}`)
  }

  create(data) {
    return axios.post('/engagements', data)
  }

  update(id, data) {
    return axios.put(`/engagements/${id}`, data)
  }

  delete(id, participationId) {
    return axios.delete(`/engagements/${id}/${participationId}`)
  }

  findByTitle(name) {
    return axios.get(`/engagements?name=${name}`)
  }
}

export default new EngagementService()
