<template>
  <div>
    <snackbar :attr="snackbar" />
    <v-dialog v-model="mentorDialog" max-width="800px">
      <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              dark
              class="primary-btn"
              elevation="0"
              v-bind="attrs"
              v-on="on"
            >
              <vue-fontawesome :icon="['fas', 'user-plus']" class="mr-1" />Add
              Mentor
            </v-btn>
          </template> -->
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeMentorDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4" lg="4">
                <v-text-field
                  v-model="mentor.firstName"
                  :rules="rules"
                  required
                  id="firstName"
                  name="firstName"
                  label="First Name"
                  hide-details="auto"
                ></v-text-field>
                <!-- <v-text-field label="Legal first name*" required></v-text-field> -->
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="4">
                <v-text-field
                  v-model="mentor.lastName"
                  :rules="rules"
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="4">
                <v-text-field
                  v-model="mentor.mobilePhone"
                  v-mask="'(###) ###-####'"
                  id="mobilePhone"
                  name="mobilePhone"
                  label="Mobile Phone"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-menu
                  ref="dateAssigned"
                  v-model="dateAssigned"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateAssignedMDY"
                      label="Date Assigned"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      ><vue-fontawesome
                        :icon="['fas', 'calendar-alt']"
                        slot="prepend-inner"
                        class="mt-1 mr-1"
                      />{{ dateAssignedYMD }}</v-text-field
                    >
                  </template>
                  <v-date-picker
                    v-model="dateAssignedYMD"
                    @change="saveDate(dateAssignedYMD, 'assigned')"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text @click="dateAssigned = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dateAssigned.save(dateAssigned)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-menu
                  ref="dateEnded"
                  v-model="dateEnded"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateEndedMDY"
                      label="Date Ended"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      ><vue-fontawesome
                        :icon="['fas', 'calendar-alt']"
                        slot="prepend-inner"
                        class="mt-1 mr-1"
                      />{{ dateEndedYMD }}</v-text-field
                    >
                  </template>
                  <v-date-picker
                    v-model="dateEndedYMD"
                    @change="saveDate(dateEndedYMD, 'ended')"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text @click="dateEnded = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dateEnded.save(dateEnded)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="mentor.reasonEnded"
                  id="reasonEnded"
                  name="reasonEnded"
                  label="Reason Ended"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  v-model="mentor.frequency"
                  @change="clearFrequencyOther"
                  clearable
                  :items="options"
                  label="Frequency of mentoring contacts"
                  hide-details="auto"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="4" lg="4">
                <v-text-field
                  v-model="mentor.frequencyOther"
                  :disabled="mentor.frequency != 'Other'"
                  id="frequencyOther"
                  name="frequencyOther"
                  label="Other"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" lg="4">
                <v-text-field
                  v-model="mentor.frequencyTotal"
                  id="frequencyTotal"
                  name="frequencyTotal"
                  label="Number of Meetings"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeMentorDialog"> Close </v-btn>
          <v-btn
            dark
            elevation="0"
            color="primary-btn"
            class="mr-4"
            @click="saveMentor"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row no-gutters>
      <v-col cols="12">
        <v-btn
          v-if="canEdit"
          :disabled="mentorCount > 2"
          small
          dark
          :class="mentorCount > 2 ? 'disabled-btn' : 'primary-btn'"
          elevation="0"
          @click="mentorDialog = true"
        >
          <vue-fontawesome :icon="['far', 'plus']" class="mr-1" />Add Mentor
        </v-btn>
        <span class="caption text--disabled ml-3">Maximum of 3 mentors</span>
        <!-- <v-btn
          v-if="canEdit"
          small
          dark
          class="primary-btn"
          elevation="0"
          @click="editParticipation()"
        >
          <vue-fontawesome :icon="['fas', 'pencil-alt']" class="mr-1" />
          Edit
        </v-btn> -->
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="mentors"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-dialog v-model="mentorDialogDelete" width="500">
              <v-card>
                <v-card-title class="headline">
                  <span class="headline">
                    <vue-fontawesome icon="exclamation-triangle" class="mr-2" />
                    Delete Mentor?</span
                  >
                </v-card-title>
                <v-card-text>
                  This mentor record will be permenantly deleted.
                  <!-- <div class="text--disabled body-2">{{ mentorId }}</div> -->
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="mentorDialogDelete = false">
                    No
                  </v-btn>
                  <v-btn text @click="deleteMentorConfirm"> Yes </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:item.lastName="{ item }">
            <b>{{ item.firstName + ' ' + item.lastName }}</b>
          </template>
          <template v-slot:item.dateAssigned="{ item }">
            {{ formatDate(item.dateAssigned) }}
          </template>
          <template v-slot:item.dateEnded="{ item }">
            {{ formatDate(item.dateEnded) }}
          </template>
          <!-- <template v-slot:item.agency="{ item }">
            {{ item.name }}
          </template> -->
          <template v-slot:item.actions="{ item }">
            <v-btn icon small @click="editMentor(item)">
              <vue-fontawesome
                small
                :icon="['fas', 'pencil-alt']"
                class="mr-1"
              />
            </v-btn>
            <v-btn icon small @click="deleteMentor(item, item.id)">
              <vue-fontawesome :icon="['fas', 'trash']" />
            </v-btn>
          </template>
          <template slot="body.append">
            <tr>
              <th colspan="6" class="text-right">{{ sumField() }}</th>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MentorService from '@/services/MentorService'
import Snackbar from '@/components/Snackbar.vue'
import { DateTime } from 'luxon'

export default {
  name: 'ParticipationMentor',
  components: {
    Snackbar
  },
  props: {
    doc: Object,
    options: Array,
    canEdit: Boolean
  },
  data() {
    return {
      snackbar: {
        model: false,
        color: '',
        message: '',
        icon: ['fas', 'exclamation-triangle'],
        timeout: 3000
      },
      dialog: false,

      dateAssigned: '',
      dateAssignedYMD: '',
      dateAssignedMDY: '',
      dateEnded: '',
      dateEndedYMD: '',
      dateEndedMDY: '',

      mentorDialog: false,
      mentorDialogDelete: false,
      mentorIndex: -1,
      mentorId: '',
      mentors: [],
      mentor: {
        id: '',
        firstName: '',
        lastName: '',
        mobilePhone: '',
        dateAssigned: '',
        dateEnded: '',
        reasonEnded: '',
        frequency: '',
        frequencyOther: '',
        frequencyTotal: ''
      },
      mentorDefault: {
        id: '',
        firstName: '',
        lastName: '',
        mobilePhone: '',
        dateAssigned: '',
        dateEnded: '',
        reasonEnded: '',
        frequency: '',
        frequencyOther: '',
        frequencyTotal: ''
      },
      rules: [v => !!v || 'This is required'],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'lastName'
        },
        {
          text: 'Phone',
          align: 'start',
          sortable: false,
          value: 'mobilePhone'
        },
        {
          text: 'Date Assigned',
          align: 'start',
          value: 'dateAssigned'
        },
        {
          text: 'Date Ended',
          align: 'start',
          value: 'dateEnded'
        },
        {
          text: 'Frequency',
          align: 'start',
          sortable: false,
          value: 'frequency'
        },
        {
          text: 'Total Meetings',
          align: 'end',
          // sortable: false,
          value: 'frequencyTotal'
        },
        {
          text: 'Actions',
          align: 'end',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  watch: {
    mentorDialogDelete(val) {
      val || this.closeMentorDelete()
    }
  },
  created() {
    // console.log(this.doc)
    MentorService.getByParticipation(this.doc.id)
      .then(response => {
        // let token = localStorage.getItem("jwt");
        // let decoded = VueJwtDecode.decode(token);
        // this.mentor = decoded;
        this.mentors = response.data
        // console.log(response.data)
      })
      .catch(error => {
        console.log('We had an error: ' + error)
      })
  },
  mounted() {},
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    formTitle() {
      return this.mentorIndex === -1 ? 'New Mentor' : 'Edit Mentor'
    },
    isEdit() {
      return this.mentorIndex === -1 ? false : true
    },
    mentorCount() {
      return this.mentors.length
    }
  },
  methods: {
    saveDate(date, ref) {
      // console.log(ref)
      if (ref === 'assigned') {
        this.dateAssignedMDY = this.formatDate(date)
      } else {
        this.dateEndedMDY = this.formatDate(date)
      }
    },
    formatDate(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date)
        .toUTC()
        .toFormat('MM/dd/yyyy')
      return dt
    },
    formatDateYMD(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date)
        .toUTC()
        .toFormat('yyyy-MM-dd')
      return dt
    },
    sumField() {
      // sum data in give key (property)
      return this.mentors.reduce(
        (a, b) => a + Number(b['frequencyTotal'] || 0),
        0
      )
    },
    clearFrequencyOther() {
      if (this.mentor.frequency != 'Other') {
        this.mentor.frequencyOther = ''
      }
    },
    editMentor(item) {
      // alert(id)
      // populate dates correctly...

      this.mentorIndex = this.mentors.indexOf(item)
      this.mentor = Object.assign({}, item)
      // console.log(item.id)
      this.dateAssignedYMD = this.formatDateYMD(this.mentor.dateAssigned)
      this.dateAssignedMDY = this.formatDate(this.mentor.dateAssigned)
      this.dateEndedYMD = this.formatDateYMD(this.mentor.dateEnded)
      this.dateEndedMDY = this.formatDate(this.mentor.dateEnded)
      this.mentorId = item.id
      this.mentorDialog = true
    },
    deleteMentor(item, id) {
      // alert(id)
      this.mentorIndex = this.mentors.indexOf(item)
      this.mentor = Object.assign({}, item)
      this.mentorId = id
      this.mentorDialogDelete = true
    },
    deleteMentorConfirm() {
      const id = this.mentorId
      // console.log(this.doc.id)
      // alert(id)
      MentorService.delete(id, this.doc.id)
        .then(response => {
          // console.log(response)
          this.mentors.splice(this.mentorIndex, 1)
          this.closeMentorDelete()
          this.snackbar.model = true
          this.snackbar.message = 'Mentor deleted'
          this.snackbar.color = 'green'
          this.snackbar.icon = ['far', 'check']
          return response
        })
        .catch(e => {
          this.closeMentorDelete()
          this.snackbar.model = true
          this.snackbar.message = 'Mentor not deleted'
          this.snackbar.color = 'red'
          this.snackbar.icon = ['fas', 'exclamation-triangle']
          console.log(e)
        })
    },
    async saveMentor() {
      const data = {
        firstName: this.mentor.firstName,
        lastName: this.mentor.lastName,
        mobilePhone: this.mentor.mobilePhone,
        dateAssigned: this.dateAssignedYMD,
        dateEnded: this.dateEndedYMD,
        reasonEnded: this.mentor.reasonEnded,
        frequency: this.mentor.frequency,
        frequencyOther: this.mentor.frequencyOther,
        frequencyTotal: this.mentor.frequencyTotal,
        participation: this.doc.id,
        citizen: this.doc.citizen.id,
        agency: this.doc.agency.id,
        agencyAbbr: this.doc.agencyAbbr
      }
      this.mentor.dateAssigned = this.dateAssignedYMD
      this.mentor.dateEnded = this.dateEndedYMD
      if (this.mentorIndex > -1) {
        // alert('updated')
        // update
        data.updatedBy = this.$store.state.user.id

        Object.assign(this.mentors[this.mentorIndex], this.mentor)
        MentorService.update(this.mentor.id, data)
          .then(() => {
            this.closeMentorDialog()
            this.snackbar.model = true
            this.snackbar.message = 'Mentor updated'
            this.snackbar.color = 'green'
            this.snackbar.icon = ['far', 'check']
          })
          .catch(e => {
            this.closeMentorDialog()
            this.snackbar.model = true
            this.snackbar.message = 'Mentor error'
            this.snackbar.color = 'red'
            this.snackbar.icon = ['fas', 'exclamation-triangle']
            console.log(e)
          })
      } else {
        data.createdBy = this.$store.state.user.id
        this.saveNewMentor(data)
      }
    },
    saveNewMentor(mentorData) {
      MentorService.create(mentorData)
        .then(response => {
          const newId = response.data.id
          // console.log(newId)
          const newMentor = Object.assign(this.mentor)
          newMentor.id = newId
          // const newMentor = Object.assign({ id: newId }, this.mentor)
          // console.log(newMentor)
          this.mentors.push(newMentor)
          this.closeMentorDialog()
          this.snackbar.model = true
          this.snackbar.message = 'Forum created'
          this.snackbar.color = 'green'
          this.snackbar.icon = ['far', 'check']
          // console.log('Created new forum successfully! ' + response.data.id)
          // this.submitted = true
        })
        .catch(err => {
          this.snackbar.model = true
          this.snackbar.message = 'No forum created'
          this.snackbar.color = 'red'
          this.snackbar.icon = ['fas', 'exclamation-triangle']
          console.log(err)
        })
    },
    closeMentorDialog() {
      this.mentorDialog = false
      // we need to clear date fields if they were set
      this.dateAssignedMDY = null
      this.dateAssignedYMD = null
      this.dateEndedMDY = null
      this.dateEndedYMD = null
      this.$nextTick(() => {
        this.mentor = Object.assign({}, this.mentorDefault)
        this.mentorIndex = -1
      })
    },
    closeMentorDelete() {
      this.mentorDialogDelete = false
      this.$nextTick(() => {
        this.mentor = Object.assign({}, this.mentorDefault)
        this.mentorIndex = -1
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg,
.disabled-btn {
  background-color: #aaaaaa !important;
}
</style>
