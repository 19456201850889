<template>
  <div>
    <snackbar :attr="snackbar" />
    <v-dialog v-model="participationDialog" max-width="800px">
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title>TYRO Enrollment</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeParticipationDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" lg="6">
              <v-checkbox
                v-model="docEdit.enrolledInCOR"
                label="Enrolled in COR Vocational Training"
                id="enrolledInCOR"
                name="enrolledInCOR"
                hide-details="auto"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-menu
                ref="CORenrollmentDate"
                v-model="CORenrollmentDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="CORenrollmentDateMDY"
                    label="Enrollment Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    ><vue-fontawesome
                      :icon="['fas', 'calendar-alt']"
                      slot="prepend-inner"
                      class="mt-1 mr-1"
                    />{{ CORenrollmentDateYMD }}</v-text-field
                  >
                </template>
                <v-date-picker
                  v-model="CORenrollmentDateYMD"
                  @change="saveDate(CORenrollmentDateYMD, 'corEnrollment')"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="CORenrollmentDate = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.CORenrollmentDate.save(CORenrollmentDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-menu
                ref="CORcompletionDate"
                v-model="CORcompletionDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="CORcompletionDateMDY"
                    label="Completion Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    ><vue-fontawesome
                      :icon="['fas', 'calendar-alt']"
                      slot="prepend-inner"
                      class="mt-1 mr-1"
                    />{{ CORcompletionDateYMD }}</v-text-field
                  >
                </template>
                <v-date-picker
                  v-model="CORcompletionDateYMD"
                  @change="saveDate(CORcompletionDateYMD, 'corCompletion')"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="CORcompletionDate = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.CORcompletionDate.save(CORcompletionDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <v-checkbox
                v-model="docEdit.enrolledInWIH"
                label="Enrolled in WIH Vocational Training"
                id="enrolledInCOR"
                name="enrolledInCOR"
                hide-details="auto"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-menu
                ref="WIHenrollmentDate"
                v-model="WIHenrollmentDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="WIHenrollmentDateMDY"
                    label="Enrollment Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    ><vue-fontawesome
                      :icon="['fas', 'calendar-alt']"
                      slot="prepend-inner"
                      class="mt-1 mr-1"
                    />{{ WIHenrollmentDateYMD }}</v-text-field
                  >
                </template>
                <v-date-picker
                  v-model="WIHenrollmentDateYMD"
                  @change="saveDate(WIHenrollmentDateYMD, 'wihEnrollment')"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="WIHenrollmentDate = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.WIHenrollmentDate.save(WIHenrollmentDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-menu
                ref="WIHcompletionDate"
                v-model="WIHcompletionDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="WIHcompletionDateMDY"
                    label="Completion Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    ><vue-fontawesome
                      :icon="['fas', 'calendar-alt']"
                      slot="prepend-inner"
                      class="mt-1 mr-1"
                    />{{ WIHcompletionDateYMD }}</v-text-field
                  >
                </template>
                <v-date-picker
                  v-model="WIHcompletionDateYMD"
                  @change="saveDate(WIHcompletionDateYMD, 'end')"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="WIHcompletionDate = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.WIHcompletionDate.save(WIHcompletionDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeParticipationDialog"> Close </v-btn>
          <v-btn elevation="0" color="primary" class="mr-4" @click="saveDoc">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row no-gutters>
      <v-col cols="12" class="d-flex flex-row">
        <v-btn
          v-if="canEdit"
          small
          dark
          class="primary-btn"
          elevation="0"
          @click="editParticipation()"
        >
          <vue-fontawesome :icon="['fas', 'pencil-alt']" class="mr-1" />
          Edit
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="12" md="6" lg="6">
        <v-simple-table>
          <tbody>
            <tr>
              <td colspan="2" :width="leftColWidth">
                Enrolled in COR WIH vocational training
                <vue-fontawesome
                  v-if="doc.enrolledInCOR"
                  :icon="['far', 'check']"
                  class="ml-2"
                />
                <vue-fontawesome v-else :icon="['far', 'ban']" class="ml-2" />
              </td>
            </tr>
            <tr v-if="doc.enrolledInCOR">
              <td colspan="2">
                <span v-if="doc.CORenrollmentDate">
                  Enrollment
                  <b>{{ formatDate(doc.CORenrollmentDate) }}</b>
                </span>
                <span v-if="doc.CORcompletionDate" class="ml-2">
                  Completion
                  <b>{{ formatDate(doc.CORcompletionDate) }}</b>
                </span>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
      <v-col cols="6" sm="12" md="6" lg="6">
        <v-simple-table>
          <tbody>
            <tr>
              <td colspan="2" :width="leftColWidth">
                Enrolled in WIH vocational training
                <vue-fontawesome
                  v-if="doc.enrolledInWIH"
                  :icon="['far', 'check']"
                  class="ml-2"
                />
                <vue-fontawesome v-else :icon="['far', 'ban']" class="ml-2" />
              </td>
            </tr>
            <tr v-if="doc.enrolledInWIH">
              <td colspan="2">
                <span v-if="doc.WIHenrollmentDate">
                  Enrollment
                  <b>{{ formatDate(doc.WIHenrollmentDate) }}</b>
                </span>
                <span v-if="doc.WIHcompletionDate" class="ml-2">
                  Completion
                  <b>{{ formatDate(doc.WIHcompletionDate) }}</b>
                </span>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ParticipationService from '@/services/ParticipationService'
import ActivityService from '@/services/ActivityService'
import options from '@/config/valueOptions'
import states from '@/config/states'
import Snackbar from '@/components/Snackbar.vue'
import sharedMixin from '@/mixins/sharedMixin.js'

export default {
  components: { Snackbar },
  name: 'ParticipationCor',
  mixins: [sharedMixin],
  props: {
    doc: Object,
    canEdit: Boolean
  },
  data() {
    return {
      snackbar: {
        model: false,
        color: '',
        message: '',
        icon: ['fas', 'exclamation-triangle'],
        timeout: 3000
      },
      panel: [0],
      CORenrollmentDate: '',
      CORenrollmentDateYMD: '',
      CORenrollmentDateMDY: '',
      CORcompletionDate: '',
      CORcompletionDateYMD: '',
      CORcompletionDateMDY: '',
      WIHenrollmentDate: '',
      WIHenrollmentDateYMD: '',
      WIHenrollmentDateMDY: '',
      WIHcompletionDate: '',
      WIHcompletionDateYMD: '',
      WIHcompletionDateMDY: '',
      states: states,
      leftColWidth: 150,
      rightColWidth: 150,
      participationEndReasons: options.participationEndReasons,
      reasonForDismissalOptions: options.reasonForDismissalOptions,
      tab: null,
      dialog: false,
      participationDialog: false,
      participationDialogDelete: false,
      citizenIndex: -1,
      docId: this.$route.params.id,
      docEdit: {
        enrolledInCOR: '',
        CORenrollmentDate: '',
        CORcompletionDate: '',
        enrolledInWIH: '',
        WIHenrollmentDate: '',
        WIHcompletionDate: '',
        employment: {
          priorToEnrollment: '',
          afterEnrollment: '',
          startStatus: '',
          endStatus: '',
          endStatus6months: '',
          endStatus12months: ''
        }
      },
      rules: [v => !!v || 'This is required'],
      emailRules: [
        // v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ]
    }
  },
  watch: {
    participationDialog(val) {
      val || this.closeParticipationDialog()
    },
    participationDialogDelete(val) {
      val || this.closeParticipationDelete()
    }
    // date(val) {
    //   if (val) {
    //     // this.dateFormatted = this.formatDate(
    //     //   this.docEdit.employment.CORenrollmentDate
    //     // )
    //     this.dateFormatted = this.formatDate(this.date)
    //   }
    // }
  },
  created() {
    // console.log(this.doc.id)
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    isEdit() {
      return this.citizenIndex === -1 ? false : true
    }
    // agencyAbbr() {
    //   return this.$store.state.user.agency.abbr || null
    // }
  },
  methods: {
    saveDate(date, ref) {
      // console.log(ref)
      if (ref === 'corEnrollment') {
        this.CORenrollmentDateMDY = this.formatDate(date)
      } else if (ref === 'corCompletion') {
        this.CORcompletionDateMDY = this.formatDate(date)
      } else if (ref === 'wihEnrollment') {
        this.WIHenrollmentDateMDY = this.formatDate(date)
      } else {
        this.WIHcompletionDateMDY = this.formatDate(date)
      }
    },
    clearDismissal() {
      if (this.doc.employment.reasonEnded != 'Dismissed') {
        this.doc.employment.reasonForDismissal = null
        this.doc.employment.reasonForDismissalOther = null
      }
    },
    clearDismissalOther() {
      if (this.doc.employment.reasonForDismissalOther != 'Other') {
        this.doc.employment.reasonForDismissalOther = null
      }
    },
    editParticipation() {
      // console.log(this.doc.citizen.firstName)

      /*
        we have to set our date formats for users as well as datepickers
      */
      this.CORenrollmentDateYMD = this.formatDatePicker(
        this.doc.CORenrollmentDate
      )
      this.CORenrollmentDateMDY = this.formatDate(this.doc.CORenrollmentDate)
      this.CORcompletionDateYMD = this.formatDatePicker(
        this.doc.CORcompletionDate
      )
      this.CORcompletionDateMDY = this.formatDate(this.doc.CORcompletionDate)
      this.WIHenrollmentDateYMD = this.formatDatePicker(
        this.doc.WIHenrollmentDate
      )
      this.WIHenrollmentDateMDY = this.formatDate(this.doc.WIHenrollmentDate)
      this.WIHcompletionDateYMD = this.formatDatePicker(
        this.doc.WIHcompletionDate
      )
      this.WIHcompletionDateMDY = this.formatDate(this.doc.WIHcompletionDate)
      this.docEdit = Object.assign({}, this.doc)
      this.participationDialog = true
    },
    async saveDoc() {
      console.log('id: ' + this.doc.id)
      // alert('component')
      const data = {
        updatedBy: this.$store.state.user.id,
        enrolledInCOR: this.docEdit.enrolledInCOR,
        CORenrollmentDate: this.CORenrollmentDateYMD,
        CORcompletionDate: this.CORcompletionDateYMD,
        enrolledInWIH: this.docEdit.enrolledInWIH,
        WIHenrollmentDate: this.WIHenrollmentDateYMD,
        WIHcompletionDate: this.WIHcompletionDateYMD
        // enrolledInWIHWIH: this.doc.enrolledInWIH,
        // WIHenrollmentDate: this.WIHenrollmentDateYMD,
        // WIHcompletionDate: this...WIHcompletionDateYMD,
        // employment: {
        //   priorToEnrollment: this.doc.employment.priorToEnrollment,
        //   afterEnrollment: this.doc.employment.afterEnrollment,
        //   startStatus: this.doc.employment.startStatus,
        //   endStatus: this.doc.employment.endStatus,
        //   endStatus6months: this.doc.employment.endStatus6months,
        //   endStatus12months: this.doc.employment.endStatus12months
        // }
      }
      try {
        const docUpdate = await ParticipationService.update(this.doc.id, data)
        // console.log(docUpdate)
        if (docUpdate.status != 200) {
          return
        }
        // since it worked, send activity log
        const activityData = {
          action: 'Updated TYRO enrollment',
          agency: this.$store.state.agency.id,
          citizen: this.doc.citizen.id,
          citizenName:
            this.doc.citizen.firstName + ' ' + this.doc.citizen.lastName,
          userName:
            this.$store.state.user.firstName +
            ' ' +
            this.$store.state.user.lastName,
          createdBy: this.$store.state.user.id
        }
        const activity = await ActivityService.create(activityData)
        if (activity.status != 200) {
          return
        }

        Object.assign(this.doc, this.docEdit)
        this.doc.CORenrollmentDate = this.CORenrollmentDateYMD
        this.doc.CORcompletionDate = this.CORcompletionDateYMD
        this.doc.WIHenrollmentDate = this.WIHenrollmentDateYMD
        this.doc.WIHcompletionDate = this.WIHcompletionDateYMD
        this.closeParticipationDialog()
        this.snackbar.model = true
        this.snackbar.message = 'Participation updated'
        this.snackbar.color = 'green'
        this.snackbar.icon = ['far', 'check']
      } catch (err) {
        this.snackbar.model = true
        this.snackbar.message = 'Participation problem!'
        this.snackbar.color = 'red'
        this.snackbar.icon = ['fas', 'exclamation-triangle']
        console.log(err)
      }
    },
    closeParticipationDialog() {
      this.participationDialog = false
      // this.$nextTick(() => {
      //   this.citizen = Object.assign({}, this.citizenDefault)
      //   // this.citizenIndex = -1
      // })
    },
    closeParticipationDelete() {
      this.participationDialogDelete = false
      this.$nextTick(() => {
        this.citizen = Object.assign({}, this.citizenDefault)
        this.citizenIndex = -1
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.v-tab {
  font-weight: normal;
  text-transform: none;
  letter-spacing: 0 !important;
}
.citizen-header {
  letter-spacing: -0.5px !important;
}
</style>
