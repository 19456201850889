<template>
  <div>
    <snackbar :attr="snackbar" />
    <v-dialog v-model="forumDialog" max-width="800px">
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeForumDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <v-text-field
                    v-model="forum.forumName"
                    :rules="rules"
                    required
                    id="forumName"
                    name="forumName"
                    label="Forum Name"
                    hide-details="auto"
                  ></v-text-field>
                  <!-- <v-text-field label="Legal first name*" required></v-text-field> -->
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <v-menu
                    ref="forumDate"
                    v-model="forumDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="forumDateMDY"
                        :rules="rules"
                        required
                        label="Forum Date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        ><vue-fontawesome
                          :icon="['fas', 'calendar-alt']"
                          slot="prepend-inner"
                          class="mt-1 mr-1"
                        />{{ forumDateYMD }}</v-text-field
                      >
                    </template>
                    <v-date-picker
                      v-model="forumDateYMD"
                      @change="saveDate(forumDateYMD, 'forum')"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text @click="forumDate = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.forumDate.save(forumDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeForumDialog"> Close </v-btn>
          <v-btn
            :disabled="!valid"
            dark
            elevation="0"
            color="primary-btn"
            class="mr-4"
            @click="saveForum"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row no-gutters>
      <v-col cols="12">
        <v-btn
          v-if="canEdit"
          small
          dark
          class="primary-btn"
          elevation="0"
          @click="forumDialog = true"
        >
          <vue-fontawesome :icon="['far', 'plus']" class="mr-1" />Add Forum
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="forums" class="elevation-1">
          <template v-slot:top>
            <v-dialog v-model="forumDialogDelete" width="500">
              <v-card>
                <v-card-title class="headline">
                  <span class="headline">
                    <vue-fontawesome icon="exclamation-triangle" class="mr-2" />
                    Delete Forum?</span
                  >
                </v-card-title>
                <v-card-text>
                  This forum record will be permenantly deleted.
                  <!-- <div class="text--disabled body-2">{{ forumId }}</div> -->
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="forumDialogDelete = false">
                    No
                  </v-btn>
                  <v-btn text @click="deleteForumConfirm"> Yes </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:item.lastName="{ item }">
            {{ item.firstName + ' ' + item.lastName }}
          </template>
          <template v-slot:item.forumDate="{ item }">
            {{ formatDate(item.forumDate) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn icon small @click="editForum(item)">
              <vue-fontawesome
                small
                :icon="['fas', 'pencil-alt']"
                class="mr-1"
              />
            </v-btn>
            <v-btn icon small @click="deleteForum(item, item.id)">
              <vue-fontawesome :icon="['fas', 'trash']" />
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ForumService from '@/services/ForumService'
import Snackbar from '@/components/Snackbar.vue'
import { DateTime } from 'luxon'

export default {
  name: 'ParticipationForum',
  components: {
    Snackbar
  },
  props: {
    doc: Object,
    options: Array,
    canEdit: Boolean
  },
  data() {
    return {
      snackbar: {
        model: false,
        color: '',
        message: '',
        icon: ['fas', 'exclamation-triangle'],
        timeout: 3000
      },
      dialog: false,
      valid: false,
      forumDate: '',
      forumDateYMD: '',
      forumDateMDY: '',

      forumDialog: false,
      forumDialogDelete: false,
      forumIndex: -1,
      forumId: '',
      forums: [],
      forum: {
        id: '',
        forumName: '',
        forumDate: '',
        dateEnded: ''
      },
      forumDefault: {
        id: '',
        forumName: '',
        forumDate: '',
        dateEnded: ''
      },
      rules: [v => !!v || 'This is required'],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'forumName'
        },
        {
          text: 'Date',
          align: 'start',
          value: 'forumDate'
        },
        {
          text: 'Actions',
          align: 'end',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  watch: {
    forumDialogDelete(val) {
      val || this.closeForumDelete()
    }
  },
  created() {
    // console.log(this.doc)
    ForumService.getByParticipation(this.doc.id)
      .then(response => {
        // let token = localStorage.getItem("jwt");
        // let decoded = VueJwtDecode.decode(token);
        // this.forum = decoded;
        this.forums = response.data
        // console.log(response.data)
      })
      .catch(error => {
        console.log('We had an error: ' + error)
      })
  },
  mounted() {},
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    formTitle() {
      return this.forumIndex === -1 ? 'New Forum' : 'Edit Forum'
    },
    isEdit() {
      return this.forumIndex === -1 ? false : true
    },
    forumCount() {
      return this.forums.length
    }
  },
  methods: {
    reset() {
      this.valid = false
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    validateForm() {
      this.$refs.form.validate()
    },
    saveDate(date, ref) {
      // console.log(ref)
      if (ref === 'forum') {
        this.forumDateMDY = this.formatDate(date)
      } else {
        this.dateEndedMDY = this.formatDate(date)
      }
    },
    formatDate(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date)
        .toUTC()
        .toFormat('MM/dd/yyyy')
      return dt
    },
    formatDateYMD(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date)
        .toUTC()
        .toFormat('yyyy-MM-dd')
      return dt
    },
    sumField() {
      // sum data in give key (property)
      return this.forums.reduce(
        (a, b) => a + Number(b['frequencyTotal'] || 0),
        0
      )
    },
    clearFrequencyOther() {
      if (this.forum.frequency != 'Other') {
        this.forum.frequencyOther = ''
      }
    },
    editForum(item) {
      // alert(id)
      // populate dates correctly...

      this.forumIndex = this.forums.indexOf(item)
      this.forum = Object.assign({}, item)
      // console.log(item.id)
      this.forumDateYMD = this.formatDateYMD(this.forum.forumDate)
      this.forumDateMDY = this.formatDate(this.forum.forumDate)
      this.forumId = item.id
      this.forumDialog = true
    },
    deleteForum(item, id) {
      // alert(id)
      this.forumIndex = this.forums.indexOf(item)
      this.forum = Object.assign({}, item)
      this.forumId = id
      this.forumDialogDelete = true
    },
    deleteForumConfirm() {
      const id = this.forumId
      // alert(id)
      ForumService.delete(id, this.doc.id)
        .then(response => {
          console.log(response)
          this.forums.splice(this.forumIndex, 1)
          this.closeForumDelete()
          this.snackbar.model = true
          this.snackbar.message = 'Forum deleted'
          this.snackbar.color = 'green'
          this.snackbar.icon = ['far', 'check']
        })
        .catch(e => {
          this.snackbar.model = true
          this.snackbar.message = 'Forum not deleted'
          this.snackbar.color = 'red'
          this.snackbar.icon = ['fas', 'exclamation-triangle']
          console.log(e)
        })
    },
    async saveForum() {
      const data = {
        forumName: this.forum.forumName,
        forumDate: this.forumDateYMD,
        participation: this.doc.id,
        citizen: this.doc.citizen.id,
        agency: this.doc.agency.id,
        agencyAbbr: this.doc.agencyAbbr
      }
      this.forum.forumDate = this.forumDateYMD
      if (this.forumIndex > -1) {
        // alert('updated')
        // update
        data.updatedBy = this.$store.state.user.id

        Object.assign(this.forums[this.forumIndex], this.forum)
        ForumService.update(this.forum.id, data)
          .then(() => {
            this.closeForumDialog()
            this.snackbar.model = true
            this.snackbar.message = 'Forum updated'
            this.snackbar.color = 'green'
            this.snackbar.icon = ['far', 'check']
          })
          .catch(e => {
            this.closeForumDialog()
            this.snackbar.model = true
            this.snackbar.message = 'Forum error'
            this.snackbar.color = 'red'
            this.snackbar.icon = ['fas', 'exclamation-triangle']
            console.log(e)
          })
      } else {
        data.createdBy = this.$store.state.user.id
        this.saveNewForum(data)
      }
    },
    saveNewForum(forumData) {
      ForumService.create(forumData)
        .then(response => {
          const newId = response.data.id
          // console.log(newId)
          const newForum = Object.assign(this.forum)
          newForum.id = newId
          // const newForum = Object.assign({ id: newId }, this.forum)
          // console.log(newForum)
          this.forums.push(newForum)
          this.closeForumDialog()
          this.snackbar.model = true
          this.snackbar.message = 'User created'
          this.snackbar.color = 'green'
          this.snackbar.icon = ['far', 'check']
          console.log('Created new forum successfully! ' + response.data.id)
          // this.submitted = true
        })
        .catch(err => {
          console.log(err)
        })
    },
    closeForumDialog() {
      this.forumDialog = false
      // we need to clear date fields if they were set
      this.forumDateMDY = null
      this.forumDateYMD = null
      this.$nextTick(() => {
        this.forum = Object.assign({}, this.forumDefault)
        this.forumIndex = -1
      })
    },
    closeForumDelete() {
      this.forumDialogDelete = false
      this.$nextTick(() => {
        this.forum = Object.assign({}, this.forumDefault)
        this.forumIndex = -1
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg,
.disabled-btn {
  background-color: #aaaaaa !important;
}
</style>
